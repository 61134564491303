import React, { useEffect, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { keyframes } from "@emotion/react"
import { Stack, Box, Divider, Paper, Typography, CircularProgress, Tooltip, IconButton } from "@mui/material"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { ReactComponent as CheckSVG } from "../../resources/icons/checkIcon.svg"
import { ReactComponent as InfoIcon } from "../../resources/icons/info.svg"
import HelpIcon from "@mui/icons-material/Help"
import { Dialog } from "@mui/material"
import { DialogContent } from "@mui/material"
import CustomDialogActions from "../reusables/CustomDialogActions"
import { Button } from "@mui/material"
import CustomDialogTitle from "../reusables/CustomDialogTitle"
import DialogCloseButton from "../reusables/DialogCloseButton"
import { toast } from "react-toastify"
import userProfileStorage from "../../utils/providers/userProfileProvider/userProfileProvider"

const GET_ROAD_COOPERATIVE_DATA = gql`
	query GetRoadCooperativeData {
		roadCooperativeWithJWT {
			id
			askedHelp
			costCentersByRoadCooperativeId {
				nodes {
					id
					establishmentsByCostCenterId {
						nodes {
							id
						}
						totalCount
					}
					propertiesByCostCenterId {
						nodes {
							id
						}
						totalCount
					}
				}
			}
			roadCooperativeMembersByRoadCooperativeId {
				nodes {
					id
				}
				totalCount
			}
			totalUnits
		}
	}
`

const CREATE_SETUP_HELP = gql`
	mutation CreateSetUpHelp($input: CreateSetUpHelpInput!) {
		createSetUpHelp(input: $input) {
			success
		}
	}
`
declare global {
	type AgendaStatusEnum = "onProgress" | "onCompleted" | "onWaiting"

	type AllStatusType = {
		roadCreated: AgendaStatusEnum
		membersCreated: AgendaStatusEnum
		establishmentsCreated: AgendaStatusEnum
	}

	type RoadCooperativeStatusValuesType = {
		createRoad: boolean
		createMembers: boolean
		createEstablishments: boolean
	}
}

type RCInfoDataType = {
	members: number
	establishments: number
	properties: number
	totalUnits: number
}

type CheckRoadCooperativeStatusPropsType = {
	setRoadCooperativeStatusValues: React.Dispatch<React.SetStateAction<RoadCooperativeStatusValuesType>>
	roadCooperativeStatusValues: RoadCooperativeStatusValuesType
}

const pulseAnimation = keyframes`
0% {
  transform: scale(1);
}
25% {
	transform: scale(1.17);
	}
50% {
	transform: scale(1.17);
}
75% {
	transform: scale(1.17);
	
}
100% {
  transform: scale(1);
}
`

const checkUpdateAllStatus = (data: any): [RCInfoDataType, AllStatusType] => {
	const cc = data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes
	const members = data.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.totalCount
	const units = data.roadCooperativeWithJWT.totalUnits

	let establishments = 0
	let properties = 0
	for (let i = 0; cc.length > i; i++) {
		establishments = establishments + cc[i].establishmentsByCostCenterId.totalCount
		properties = properties + cc[i].propertiesByCostCenterId.totalCount
	}
	const RCInfoDataObj = {
		members: members,
		establishments: establishments,
		properties: properties,
		totalUnits: data.roadCooperativeWithJWT.totalUnits
	}
	let allStatusTemp: AllStatusType = {
		roadCreated: "onProgress",
		membersCreated: "onWaiting",
		establishmentsCreated: "onWaiting"
	}
	if (cc.length > 0) {
		allStatusTemp = { ...allStatusTemp, roadCreated: "onCompleted", membersCreated: "onProgress" }
		if (cc[0].establishmentsByCostCenterId.totalCount > 0) {
			allStatusTemp = { ...allStatusTemp, establishmentsCreated: "onCompleted" }
		}
		if (members > 1) {
			allStatusTemp = { ...allStatusTemp, membersCreated: "onCompleted", establishmentsCreated: "onProgress" }
		}
		if (units > 0) {
			allStatusTemp = { ...allStatusTemp, establishmentsCreated: "onCompleted" }
		}
	}
	return [RCInfoDataObj, allStatusTemp]
}

const CheckRoadCooperativeStatus = ({ setRoadCooperativeStatusValues, roadCooperativeStatusValues }: CheckRoadCooperativeStatusPropsType) => {
	const [RCInitializeCompleted, setRCInitializeCompleted] = useState(false)
	const [askedHelp, setAskedHelp] = useState(false)
	const [loadet, setLoadet] = useState(false)
	const [allStatus, setAllStatus] = useState<AllStatusType>({
		roadCreated: "onProgress",
		membersCreated: "onProgress",
		establishmentsCreated: "onProgress"
	})
	const [selectedView, setSelectedView] = useState<"RCInit" | "RCInfo">("RCInit")
	const [RCInfoData, setRCInfoData] = useState<RCInfoDataType>({
		members: 0,
		establishments: 0,
		properties: 0,
		totalUnits: 0
	})

	const { data, loading, error, refetch } = useQuery(GET_ROAD_COOPERATIVE_DATA, {
		onError(error) {
			console.log(error)
		}
	})

	const permission = userProfileStorage({ type: "getActiveRoadCooperative" }).permission

	useEffect(() => {
		if (!data) return
		const [RCInfoData, allStatusTemp] = checkUpdateAllStatus(data)
		setAllStatus(allStatusTemp)
		setRCInfoData(RCInfoData)
		setAskedHelp(data.roadCooperativeWithJWT.askedHelp)
		if (
			allStatusTemp.establishmentsCreated == "onCompleted" &&
			allStatusTemp.membersCreated == "onCompleted" &&
			allStatusTemp.roadCreated == "onCompleted"
		) {
			setRCInitializeCompleted(true)
			setSelectedView("RCInfo")
		}
		setLoadet(true)
	}, [data])

	useEffect(() => {
		refetch()
	}, [roadCooperativeStatusValues])

	return (
		<>
			{loadet && (selectedView !== "RCInit" || permission === "edit") && (
				<Paper
					id="roadCooperativeInfoPaper"
					sx={{
						width: "310px",
						padding: "10px",
						zIndex: "1",
						borderRadius: "10px",
						"@media screen and (max-width: 413px)": {
							display: "none",
							paddingRight: "0px",
							marginRight: "0px",
							marginLeft: "0px",
							paddingLeft: "0px"
						}
					}}
					elevation={5}
				>
					{
						{
							RCInfo: <RoadCooperativeInformationView RCInfoData={RCInfoData} />,
							RCInit: permission === "edit" && (
								<RoadCooperativeInitializeView
									allStatus={allStatus}
									setRoadCooperativeStatusValues={setRoadCooperativeStatusValues}
									askedHelp={askedHelp}
									refetch={refetch}
								/>
							)
						}[selectedView]
					}
				</Paper>
			)}

			{!loadet && (
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "80px"
					}}
				>
					<CircularProgress />
				</Box>
			)}
		</>
	)
}

type RoadCooperativeInfoViewPropsType = {
	RCInfoData: RCInfoDataType
}

const RoadCooperativeInformationView = ({ RCInfoData }: RoadCooperativeInfoViewPropsType) => {
	return (
		<>
			<Typography
				sx={{
					fontSize: "1rem"
				}}
			>
				Tiekunnan tiedot
			</Typography>
			<Divider></Divider>
			<Typography sx={{ fontWeight: "500" }}>Osakkaat: {RCInfoData.members} kpl</Typography>
			<Divider></Divider>
			<Typography sx={{ fontWeight: "500" }}>Kiinteistöt: {RCInfoData.properties} kpl</Typography>
			<Divider></Divider>
			<Typography sx={{ fontWeight: "500" }}>Yksiköt: {RCInfoData.totalUnits} kpl</Typography>
		</>
	)
}

type RoadCooperativeInitializeViewPropsType = {
	allStatus: AllStatusType
	setRoadCooperativeStatusValues: React.Dispatch<
		React.SetStateAction<{
			createRoad: boolean
			createMembers: boolean
			createEstablishments: boolean
		}>
	>
	askedHelp: boolean
	refetch: () => void
}

const RoadCooperativeInitializeView = ({ allStatus, setRoadCooperativeStatusValues, askedHelp, refetch }: RoadCooperativeInitializeViewPropsType) => {
	const [open, setOpen] = useState(false)
	const [showThankYouMessage, setShowThankYouMessage] = useState(false)
	const [createSetUpHelp, { loading }] = useMutation(CREATE_SETUP_HELP)
	const currentRC = userProfileStorage({ type: "getActiveRoadCooperative" })
	const roadCooperativeName = currentRC.roadCooperative

	const handleSendSetUp = async () => {
		try {
			const response = await createSetUpHelp({ variables: { input: { roadCooperativeName } } })
			if (response.data.createSetUpHelp.success) {
				toast.success(response.data.createSetUpHelp.message)
				setOpen(false)
				setShowThankYouMessage(true)
				setTimeout(() => setShowThankYouMessage(false), 5000)
			} else {
				toast.error("Virhe pyynnön lähettämisessä: " + response.data.createSetUpHelp.message)
			}
		} catch (error: any) {
			toast.error("Virhe pyynnön lähettämisessä: " + error.message)
		}
		refetch()
	}

	const handleInfoClick = () => {
		const url = " https://tuki.tievahti.fi/tievahdin-kayttoonotto/"
		const link = document.createElement("a")
		link.href = url
		;(link.rel = "noreferrer"), (link.target = "_blank"), link.click()
	}

	const handleHelpClick = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const fadeInUp = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

	const fadeOutDown = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100px);
    opacity: 0;
  }
`

	useEffect(() => {
		if (showThankYouMessage) {
			const timer = setTimeout(() => {
				setShowThankYouMessage(false)
			}, 5000)
			return () => clearTimeout(timer)
		}
	}, [showThankYouMessage])

	return (
		<>
			<Stack direction="row" alignItems="center" justifyContent="center">
				<Typography
					sx={{
						fontSize: "1.2rem"
					}}
				>
					Tievahdin käyttöönotto
				</Typography>
				<Tooltip sx={{ marginLeft: "5px" }} title="Katso ohjeet Tievahdin käyttöönottoon.">
					<IconButton sx={{ bottom: "2px" }} onClick={handleInfoClick}>
						<InfoIcon />
					</IconButton>
				</Tooltip>
				<Tooltip sx={{ marginLeft: "5px" }} title="Pyydä apua Tievahdin käyttöönottoon.">
					<IconButton sx={{ bottom: "2px", marginBottom: "-3px" }} onClick={handleHelpClick}>
						<HelpIcon color="secondary" sx={{ animation: `${pulseAnimation} 2s ease-in-out infinite` }} />
					</IconButton>
				</Tooltip>
			</Stack>
			<Divider></Divider>
			<StatusAgenda
				setRoadCooperativeStatusValues={setRoadCooperativeStatusValues}
				activeStatusValue={"createRoad"}
				agendaStatus={allStatus.roadCreated}
				agendaName="1. Lisää tie ja kiinteistöt"
			/>
			<StatusAgenda
				setRoadCooperativeStatusValues={setRoadCooperativeStatusValues}
				activeStatusValue={"createMembers"}
				agendaStatus={allStatus.membersCreated}
				agendaName="2. Lisää osakkaat"
			/>
			<StatusAgenda
				setRoadCooperativeStatusValues={setRoadCooperativeStatusValues}
				activeStatusValue={"createEstablishments"}
				agendaStatus={allStatus.establishmentsCreated}
				agendaName="3. Lisää yksiköt"
			/>
			<Dialog open={open}>
				<DialogCloseButton closeFunction={handleClose} />
				<CustomDialogTitle>Pyydä apua Tievahdin käyttöönottoon</CustomDialogTitle>
				<DialogContent>
					Mikäli tarvitset apua Tievahdin käyttöönotossa, autamme mielellämme. Lisäämme puolestanne tiekunnan tien, kiinteistöt, osakkaat sekä yksiköt
					olemassa olevien tietojenne pohjalta. Autamme teitä pääsemään alkuun Tievahdin talous-osion kanssa, jota hyödyntämällä voitte hoitaa
					tiekuntanne kirjanpidon ja tiemaksujen laskutuksen. Käyttöönoton jälkeen käymme vielä yhdessä läpi ohjelman loputkin ominaisuudet, jonka
					tavoitteena on että pystyisitte jatkossa käyttämään Tievahtia itsenäisesti. Palvelun hinta on <b>250 € (alv 0 %)</b>.
				</DialogContent>
				<CustomDialogActions>
					<Button onClick={handleClose} color="cancel" variant="outlined">
						Sulje
					</Button>
					<Button onClick={handleSendSetUp} color="primary" variant="contained" disabled={loading}>
						{loading ? "Lähetetään..." : "Lähetä pyyntö"}
					</Button>
				</CustomDialogActions>
			</Dialog>
			{showThankYouMessage && (
				<Paper
					elevation={5}
					sx={{
						zIndex: 3,
						position: "fixed",
						left: "calc(50vw - 100px)",
						top: "calc(100vh - 130px)",
						width: "200px",
						padding: "25px",
						animation: `${fadeInUp} 1s, ${fadeOutDown} 1s 4s`
					}}
				>
					<Typography color="primary" sx={{ textAlign: "center" }}>
						Kiitos yhteydenotosta. Palaamme asiaan pian.
					</Typography>
				</Paper>
			)}
		</>
	)
}

type StatusAgendaType = {
	agendaName: string
	agendaStatus: AgendaStatusEnum
	setRoadCooperativeStatusValues: React.Dispatch<
		React.SetStateAction<{
			createRoad: boolean
			createMembers: boolean
			createEstablishments: boolean
		}>
	>
	activeStatusValue: string
}

const StatusAgenda = ({ agendaName, agendaStatus, activeStatusValue, setRoadCooperativeStatusValues }: StatusAgendaType) => {
	const handleButton = () => {
		switch (activeStatusValue) {
			case "createRoad":
				setRoadCooperativeStatusValues((prevValue) => {
					return { ...prevValue, createRoad: true }
				})
				break
			case "createMembers":
				setRoadCooperativeStatusValues((prevValue) => {
					return { ...prevValue, createMembers: true }
				})
				break
			case "createEstablishments":
				setRoadCooperativeStatusValues((prevValue) => {
					return { ...prevValue, createEstablishments: true }
				})
				break
			default:
				break
		}
	}

	return (
		<>
			<Stack
				direction="row"
				spacing={3}
				sx={{
					height: "40px",
					alignItems: "center",
					justifyContent: "space-between"
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row"
					}}
				>
					{/* {
						{
							onProgress: (
								<>
									<InfoIcon />
								</>
							),
							onWaiting: (
								<>
									<InfoIcon
									/>
								</>
							),
							onCompleted: (
								<>
									<CheckSVG style={{ width: "21px", height: "21px", marginRight: "3px" }} />
								</>
							),
						}[agendaStatus]
					} */}
					<Typography sx={{ fontWeight: "500" }}>{agendaName}</Typography>
				</Box>
				{agendaStatus == "onWaiting" ? (
					<>
						<AddCircleIcon
							color="disabled"
							sx={{
								width: "20px",
								height: "20px"
							}}
							// onClick={handleButton}
						/>
					</>
				) : null}
				{agendaStatus == "onProgress" ? (
					<>
						<Tooltip title="Lisää">
							<AddCircleIcon
								color="primary"
								sx={{
									animation: ` ${pulseAnimation} 2s ease-in-out infinite`,
									width: "20px",
									height: "20px",
									"&:hover": { cursor: "pointer" }
								}}
								onClick={handleButton}
							/>
						</Tooltip>
					</>
				) : null}
				{agendaStatus == "onCompleted" ? (
					<>
						<CheckSVG style={{ width: "21px", height: "21px" }} />
					</>
				) : null}
			</Stack>
			<Divider></Divider>
		</>
	)
}

export default CheckRoadCooperativeStatus
