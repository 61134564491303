import { ApolloQueryResult, gql, OperationVariables, useMutation, useQuery } from "@apollo/client"
import {
	Typography,
	Stack,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	TableHead,
	Table,
	Paper,
	CircularProgress,
	Chip,
	Dialog,
	DialogContent,
	Button,
	TextField,
	Box,
	MenuItem
} from "@mui/material"
import React, { useEffect, useState } from "react"
import RoadUsageUnitsTableRow from "./RoadUsageUnitsTableRow"
import AddIcon from "@mui/icons-material/Add"
import DialogCloseButton from "../../reusables/DialogCloseButton"
import CustomDialogTitle from "../../reusables/CustomDialogTitle"
import userProfileStorage from "../../../utils/providers/userProfileProvider/userProfileProvider"
import CustomDialogActions from "../../reusables/CustomDialogActions"
import MonetaryInput from "../../../utils/MonetaryInput"
import { toast } from "react-toastify"

const GET_ALL_ROAD_USAGE_UNITS = gql`
	query GetAllRoadUsageUnits {
		roadCooperativeWithJWT {
			roadUsageUnitsByRoadCooperativeId {
				nodes {
					id
					roadUsageUnit
					roadUsageUnitType
					roadUsageUnitPurpose
					weight
					establishmentsByRoadUsageUnitId {
						totalCount
					}
				}
			}
		}
	}
`

const CREATE_ROAD_USAGE_UNIT = gql`
	mutation MyMutation2(
		$roadCooperativeId: UUID
		$roadUsageUnit: String = ""
		$roadUsageUnitType: String = ""
		$roadUsageUnitPurpose: UsageUnitPurposeType
		$weight: Float = 1.5
	) {
		createRoadUsageUnit(
			input: {
				roadUsageUnit: {
					roadCooperativeId: $roadCooperativeId
					roadUsageUnit: $roadUsageUnit
					roadUsageUnitType: $roadUsageUnitType
					roadUsageUnitPurpose: $roadUsageUnitPurpose
					weight: $weight
				}
			}
		) {
			clientMutationId
		}
	}
`

export const UNITIZATION_ROAD_USAGE_UNIT_MUTATIONS = gql`
	mutation UnitizationRoadUsageUnitMutations(
		$weight: Float = 1.5
		$roadUsageUnitType: String = ""
		$roadUsageUnitPurpose: UsageUnitPurposeType
		$roadUsageUnit: String = ""
		$oldUnitType: String
		$type: String
		$from: String
		$unitizationRoadUsageUnitId: Int
		$id: Int
		$unitizationId: Int
	) {
		updateUnitizationRoadUsageUnitExtended(
			input: {
				unitizationRoadUsageUnitPatch: {
					roadUsageUnitPurpose: $roadUsageUnitPurpose
					roadUsageUnitType: $roadUsageUnitType
					weight: $weight
					roadUsageUnit: $roadUsageUnit
					unitizationId: $unitizationId
				}
				oldUnitType: $oldUnitType
				type: $type
				from: $from
				unitizationRoadUsageUnitId: $unitizationRoadUsageUnitId
				id: $id
			}
		) {
			clientMutationId
		}
	}
`

const RoadUsageUnitsTable = () => {
	const [usageUnitsState, setUsageUnitState] = useState<any>()
	const [openCreateRoadUsageUnit, setOpenCreateRoadUsgeUnit] = useState<boolean>(false)
	const [usageUnitType, setUsageUnitType] = useState("")
	const [usageUnit, setUsageUnit] = useState("")
	const [weight, setWeight] = useState(0)
	const [usagePurpose, setUsagePurpose] = useState<UsagePurposeType>(null)
	const currentUserRC = userProfileStorage({ type: "getActiveRoadCooperative" })

	const [selectedMember, setSelectedMember] = useState<number>()

	const handleCloseCreateUsageUnit = () => {
		setOpenCreateRoadUsgeUnit(false)
		setUsageUnit("")
		setUsageUnitType("")
		setWeight(0)
	}

	const {
		data: roadUsageUnits,
		loading: roadUsageUnitsLoading,
		error: roadUsageUnitsError,
		refetch: roadUsageUnitsRefetch
	} = useQuery(GET_ALL_ROAD_USAGE_UNITS, {
		onCompleted: (data) => {
			console.log(data)
		}
	})

	const [createRoadUsageUnit] = useMutation(CREATE_ROAD_USAGE_UNIT, {
		onCompleted: () => {
			console.log(" --------------------- ROAD USAGE UNIT CREATED -------------")

			roadUsageUnitsRefetch()
		}
	})

	const [createUnitizationRoadUsageUnit] = useMutation(UNITIZATION_ROAD_USAGE_UNIT_MUTATIONS, {
		onCompleted: () => {
			console.log(" ------------- UNITIZATION ROAD USAGE UNIT UPDATED ----------- ")
			roadUsageUnitsRefetch()
		}
	})

	const {
		data: usageUnitsData,
		loading: usageUnitsLoading,
		error: usageUnitsError,
		refetch: usageUnitsRefetch
	} = useQuery(GET_ALL_ROAD_USAGE_UNITS, {
		onCompleted: (data) => {
			null
			// console.log(data, " ---------- ")
		}
	})

	const handleCreateRoadUsageUnit = () => {
		// createRoadUsageUnit({
		// 	variables: {
		// 		roadUsageUnit: usageUnit,
		// 		roadUsageUnitType: usageUnitType,
		// 		weight: weight,
		// 		roadUsageUnitPurpose: usagePurpose,
		// 		roadCooperativeId: currentUserRC.id,
		// 	},
		// })
		createUnitizationRoadUsageUnit({
			variables: {
				roadUsageUnitPurpose: usagePurpose,
				roadUsageUnitType: usageUnitType,
				weight: weight,
				roadUsageUnit: usageUnit,
				oldUnitType: null,
				type: "create",
				from: "home",
				unitizationRoadUsageUnitId: null,
				roadCooperativeId: currentUserRC.id
			}
		})
			.then((res) => {
				console.log("Onnistui")
			})
			.catch((error) => {
				console.error("Virhe tapahtui:", error)
				toast.error(
					toast.error(
						"Liikennelajia ei onnistuttu Lisäämään yksiköintityökaluun. Käy lisäämässä se manuaalisesti kohdasta 'Yksiköintityökalut'=>'Hallinnoi liikennelajeja'"
					)
				)
			})
		setOpenCreateRoadUsgeUnit(false)
		setUsageUnit("")
		setUsageUnitType("")
		setWeight(0)
	}

	const handleSelect = (v: UsagePurposeType) => {
		setUsagePurpose(v)
	}

	useEffect(() => {
		if (usageUnitsData) setUsageUnitState(usageUnitsData.roadCooperativeWithJWT.roadUsageUnitsByRoadCooperativeId.nodes)
	}, [usageUnitsData, usageUnitsRefetch])

	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: "5px" }}>
				<Typography sx={{ fontSize: "1.2rem" }}>Tienkäytön perusteet</Typography>
				<Chip icon={<AddIcon />} label="Lisää" variant="contained" color="primary" onClick={() => setOpenCreateRoadUsgeUnit(true)} />
			</Stack>
			<Paper
				elevation={5}
				sx={{
					// justifyContent: 'center',
					// textAlign: 'center',
					maxHeight: "85vh",
					overflow: "auto",
					alignSelf: screen.width > 600 ? "center" : "normal"
				}}
			>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell sx={{ fontWeight: "700" }}>Liikennelaji</TableCell>
								<TableCell sx={{ fontWeight: "700" }}>Lyhenne</TableCell>
								<TableCell sx={{ fontWeight: "700" }}>Painoluku (t)</TableCell>
								<TableCell sx={{ width: "30px" }}></TableCell>
								<TableCell />
							</TableRow>
						</TableHead>
						<TableBody>
							{!usageUnitsState
								? null
								: usageUnitsState.map((unit: any) => {
										return (
											<React.Fragment key={unit.id}>
												<RoadUsageUnitsTableRow unit={unit} usageUnitsRefetch={usageUnitsRefetch} />
											</React.Fragment>
										)
									})}
						</TableBody>
					</Table>
				</TableContainer>
				{usageUnitsLoading ? <CircularProgress /> : null}
				{usageUnitsError ? <>error</> : null}
			</Paper>

			<Dialog open={openCreateRoadUsageUnit} maxWidth={"xs"} fullWidth>
				<DialogCloseButton closeFunction={() => setOpenCreateRoadUsgeUnit(false)} />
				<CustomDialogTitle>Luo liikennelaji</CustomDialogTitle>
				<DialogContent>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column"
						}}
					>
						<TextField onChange={(e: { target: { value: string } }) => setUsageUnitType(e.target.value)} label="Liikennelaji" />
						<TextField onChange={(e: { target: { value: string } }) => setUsageUnit(e.target.value)} label="Lyhenne" />
						<MonetaryInput maximumDecimals={2} valueSetter={(v: number) => setWeight(v)} label="Painoluku (tonnia)" value={0} />
						<TextField
							select
							label="Verotuslaji"
							value={usagePurpose ?? 0}
							sx={{ textAlign: "left" }}
							onChange={(e: { target: { value: UsagePurposeType | 0 } }) => handleSelect(e.target.value == 0 ? null : e.target.value)}
						>
							<MenuItem value={0}>Yksityiskäyttö</MenuItem>
							<MenuItem value={"BUSINESS"}>Yritystoiminta</MenuItem>
							<MenuItem value={"AGRICULTURE"}>Maatalous</MenuItem>
							<MenuItem value={"FORESTRY"}>Metsätalous</MenuItem>
						</TextField>
					</Box>
				</DialogContent>
				<CustomDialogActions>
					<Button
						sx={{
							maxWidth: "120px"
						}}
						fullWidth
						variant="outlined"
						onClick={handleCloseCreateUsageUnit}
					>
						Sulje
					</Button>

					<Button
						sx={{
							maxWidth: "120px"
						}}
						fullWidth
						variant="contained"
						onClick={handleCreateRoadUsageUnit}
					>
						Tallenna
					</Button>
				</CustomDialogActions>
			</Dialog>
		</>
	)
}

export default RoadUsageUnitsTable
