import React, { MutableRefObject, useEffect, useRef, useState } from "react"
import maplibregl, { LngLatLike } from "maplibre-gl"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"

import "./Map.css"
import KipaLayersFilterBox from "./mapComponents/KipaLayersFilterBox"
import selectPropertiesFromMap from "./mapUtils/selectPropertiesFromMap"

import MapSearchBox from "./mapComponents/MapSearchBox"
import "react-toastify/dist/ReactToastify.css"
import mapClickFunction from "./mapUtils/getRoadByClick"
import loadKipaLayers from "./mapUtils/loadKipaLayers"
import getRoadLinkByClick from "./mapUtils/getRoadLinkByClick"
import searchPlaceByName from "./mapUtils/searchPlaceByName"
import { gql, useQuery } from "@apollo/client"
import { costCenterLinksVar, costCenterPropertyVar } from "../../network/apolloClient/apolloClientMakeVars"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import selectedRoadLinksValues from "./mapUtils/selectedRoadLinksValues"
import waitUntilMapLoaded from "./mapUtils/waitUntilMapLoaded"

import * as turf from "@turf/turf"
import { Typography } from "@mui/material"
import { Paper } from "@mui/material"
import selectedCostCentersLinks from "./mapUtils/selectedCostCentersLinks"
import { Dialog } from "@mui/material"
import { Button } from "@mui/material"
import { DialogActions } from "@mui/material"
import { DialogContent } from "@mui/material"
import { TextField } from "@mui/material"
import showPropertyInfoByClickFunction from "./mapUtils/showPropertyInfoByClickFunction"
import CustomDialogTitle from "../reusables/CustomDialogTitle"
import CustomDialogActions from "../reusables/CustomDialogActions"

import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import MapPropertyInfoPopup from "./mapComponents/MapPropertyInfoPopoup"
import config from "../../utils/config"
import { ToastContainer } from "react-toastify"

const GET_PROPERTIES = gql`
	query GetProperties {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					propertiesByCostCenterId {
						nodes {
							id
							mmlPropertyId
							plotId
							propertyName
							propertyTag
							establishmentsByPropertyId {
								nodes {
									roadUnits
									roadUsageUnitId
									roadUsageUnitByRoadUsageUnitId {
										roadUsageUnitType
										weight
										roadUsageUnit
										id
									}
									additionalInformation
									amount
									discretionalyCorrectionMultiplier
									distanceCorrectionMultiplier
									lateralDirectionCorrectionMultiplier
									lateralDirection
									operatingDistance
									roadCooperativeMemberByRoadCooperativeMemberId {
										name
										id
										userEmail
									}
									weightCorrectionMultiplier
									id
								}
							}
							costCenterByCostCenterId {
								idColor
								name
								id
							}
							id
						}
						totalCount
					}
					idColor
					name
					id
				}
			}
		}
	}
`

const GET_ALL_COSTCENTERS_NAMES = gql`
	query MyQuery3 {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					name
				}
			}
		}
	}
`

const costCenterLinksQuery = gql`
	query getCostCenterLinks {
		costCenterLinksVar @client
	}
`

const costCenterPropertyQuery = gql`
	query getCostCenterProperty {
		costCenterPropertyVar @client
	}
`
interface SelectedRoadLinksType {
	coordinates: [LngLatLike]
	linkId?: string
	linkLength?: number
	id?: number
}

interface selectedPropertiesType {
	property: string
	propertyId: number
}

interface selectedCostCenter {
	roadLinks: SelectedRoadLinksType[]
	costCenterIdColor: string
	costCenterData: any
}

interface MapComponentType {
	executeFunc?: () => void
	katunimi?: string
	kuntanimi?: string
	showPropertiesBorder?: boolean
	draw?: boolean
	showSelectedRoadLinks?: boolean
	selectedRoadLinks?: any
	selectedCostCentersLinksValue?: selectedCostCenter[]
	mapClick?: boolean
	selectRoadLinks?: boolean
	selectProperties?: boolean
	selectedProperties?: any
	costCenterPropertyList?: any
	setCenterCoorinates?: boolean
	flyTo?: boolean
	id?: string
	filterBox?: boolean
	mapSearchBox?: boolean
	showPropertiesFromList?: any
	showSelectedPropertiesFromList?: any
	showCostCentersProperties?: any
	ShowDrawingsCalculations?: boolean
	updateCostCenterRoadLinks?: any
	editRoadLinks?: boolean
	editProperties?: boolean
	activeCostCenterName?: string
	activeCostCenterColor?: string
	showSelectedProperties?: boolean
	showPropertyInfoByClick?: boolean
	setCenterLatLng?: { lat: number; lng: number }
}

const useReferredState = (initialValue: any = undefined): [any, React.MutableRefObject<any>, React.Dispatch<any>] => {
	const [state, setState] = useState<any>(initialValue)
	const reference = useRef<any>(state)

	const setReferredState = (value: any) => {
		reference.current = value
		setState(value)
	}

	return [state, reference, setReferredState]
}

const MapComponent = ({
	executeFunc,
	katunimi,
	kuntanimi,
	showPropertiesBorder,
	draw,
	showSelectedRoadLinks,
	selectedRoadLinks,
	mapClick,
	selectRoadLinks,
	selectProperties,
	costCenterPropertyList,
	selectedProperties,
	setCenterCoorinates,
	selectedCostCentersLinksValue,
	flyTo,
	id,
	filterBox,
	mapSearchBox,
	showPropertiesFromList,
	showSelectedPropertiesFromList,
	ShowDrawingsCalculations,
	updateCostCenterRoadLinks,
	editRoadLinks,
	editProperties,
	activeCostCenterName,
	activeCostCenterColor,
	showSelectedProperties,
	showPropertyInfoByClick,
	setCenterLatLng
}: MapComponentType) => {
	const { data, loading, error } = useQuery(GET_ALL_COSTCENTERS_NAMES)

	const costCenterLinksData = useQuery(costCenterLinksQuery)
	const costCenterPropertyData = useQuery(costCenterPropertyQuery)

	const [zoom, setZoom] = useState<number>(8)

	const [centerLng, setCenterLng] = useState<number>(64.101)
	const [centerLat, setCenterLat] = useState<number>(26.645)

	const [drawPolygonCalculate, setDrawPolygonCalculate] = useState<any>(0)
	const [drawLineCalculate, setDrawLineCalculate] = useState(0)

	const [selectedPropertyName, setSelectedPropertyName] = useState("")

	const [selectedLinks, setSelectedLinks] = useState<SelectedRoadLinksType[]>(costCenterLinksData.data.costCenterLinksVar.roadLinks)
	const [updateCostCenterRoadLinksState, setupdateCostCenterRoadLinksState] = useState(updateCostCenterRoadLinks)
	const [propertyDialogData, setPropertyDialogData] = useState<{ open: boolean; data: { addProperty: boolean } | any; mmlPropertyId: string } | any>(null)
	const [firstTimeStateUpdated, setFirstTimeStateUpdated] = useState<boolean>(false)
	const [activeRoadLinksSelectEffect, setActiveRoadLinksSelectEffect] = useState(false)

	const [updateCostCenterPropertiesState, setUpdateCostCenterPropertiesState] = useState(costCenterPropertyList)
	const [firstTimePropertiesStateUpdate, setFirstTimePropertiesStateUpdate] = useState(false)

	const [showSelectedPropertyPopupAdd, setShowSelectedPropertyPopupAdd] = useState<any>(null)
	const [showSelectedPropertyPopupRemove, setShowSelectedPropertyPopupRemove] = useState<any>(null)
	const [_selectedPropertyState, _setSelectedPropertyState] = useState<Property | null>(null)
	const [_propertyList, _setPropertyList] = useState(costCenterPropertyData.data.costCenterPropertyVar.properties)
	const [openPropertyInfoDialog, setOpenPropertyInfoDialog] = useState(false)
	const selectedPropertyState = useRef(_selectedPropertyState)
	const setSelectedPropertyState = (x: any) => {
		selectedPropertyState.current = x
		_setSelectedPropertyState(x)
	}

	const propertyList = useRef(_propertyList)
	const setPropertyList = (x: any) => {
		propertyList.current = x
		_setPropertyList(x)
	}

	const { data: allPropertiesData, refetch: refetchMapPropertiesData } = useQuery(GET_PROPERTIES)

	type MapContainerRefType = React.MutableRefObject<HTMLElement | null>

	const mapContainerRef: any = useRef(null)
	const map: any = useRef(null)
	const drawFunc: MutableRefObject<MapboxDraw> = useRef(new MapboxDraw())

	const roadLinkClickRef = useRef(null)
	const propertiesSelectRef = useRef(null)
	const propertiesClickRef = useRef(null)
	const propertiesButtonRef = useRef(null)

	const updateArea = (e: any) => {
		const data: any = drawFunc.current.getAll()
		if (data.features.length > 0) {
			for (let i = 0; data.features.length > i; i++) {
				if (data.features[i].geometry.type == "LineString") {
					const line = turf.lineString(data.features[i].geometry.coordinates)
					const length = turf.length(line, { units: "meters" })
					setDrawLineCalculate(length)
				}

				if (data.features[i].geometry.type == "Polygon") {
					try {
						const polygon: any = turf.polygon([data.features[0].geometry.coordinates[0]])
						const area = turf.area(polygon)
						const rounded_area = Math.round(area * 100) / 100
						setDrawPolygonCalculate(rounded_area)
					} catch (error) {
						console.error(error)
					}
				}
			}
		}
	}

	const selectArea = (e: any) => {
		const data: any = e
		if (data.features.length > 0) {
			for (let i = 0; data.features.length > i; i++) {
				if (data.features[i].geometry.type == "LineString") {
					const line = turf.lineString(data.features[i].geometry.coordinates)
					const length = turf.length(line, { units: "meters" })
					setDrawLineCalculate(length)
				}

				if (data.features[i].geometry.type == "Polygon") {
					try {
						const polygon: any = turf.polygon([data.features[0].geometry.coordinates[0]])
						const area = turf.area(polygon)
						const rounded_area = Math.round(area * 100) / 100
						setDrawPolygonCalculate(rounded_area)
					} catch (error) {
						console.error(error)
					}
				}
			}
		}
	}

	useEffect(() => {
		if (setCenterLatLng) {
			setZoom(14)
			setCenterLng(setCenterLatLng.lng)
			setCenterLat(setCenterLatLng.lat)
		}
	}, [setCenterLatLng])

	useEffect(() => {
		if (katunimi && kuntanimi) {
			const func = async () => {
				const latLng: Array<number> | undefined = await searchPlaceByName(kuntanimi, katunimi)

				if (latLng) {
					setCenterLat(latLng[1])
					setCenterLng(latLng[0])
					map.current.setCenter([latLng[1], latLng[0]])
					// map.current.option.center([latLng[1], latLng[0]]);
					map.current.setZoom(14)
				}
			}
			func()
		}

		if (!centerLat && !centerLng && !zoom) return
		map.current = new maplibregl.Map({
			container: mapContainerRef.current ?? "",
			style: `https://avoin-karttakuva.maanmittauslaitos.fi/vectortiles/stylejson/v20/backgroundmap.json?TileMatrixSet=WGS84_Pseudo-Mercator&api-key=${config.MML_API_KEY}`,
			center: [centerLat, centerLng],
			zoom: zoom
		})

		if (!map) return // wait for map to initialize

		map.current.doubleClickZoom.disable()

		map.current.on("load", () => {
			loadKipaLayers(map) // loading MML kiinteistöpalvelu layer

			map.current.on("draw.create", (e: any) => {
				updateArea(e)
			})
			map.current.on("draw.delete", updateArea)
			map.current.on("draw.update", updateArea)

			if (showPropertiesBorder) {
				map.current.setLayoutProperty("KiinteistotunnuksenSijaintitiedot", "visibility", "visible")
				map.current.setLayoutProperty("KiinteistorajanSijaintitiedot", "visibility", "visible")
				map.current.setLayoutProperty("PalstanSijaintitiedot", "visibility", "visible")
				// map.current.setLayoutProperty("RajamerkinSijaintitiedot", 'visibility', 'visible')
				// map.current.setLayoutProperty("RajamerkinSijaintitiedot_tunnus", 'visibility', 'visible')
			}

			if (selectRoadLinks) {
				costCenterLinksData.refetch()
				getRoadLinkByClick(map, selectedLinks, setSelectedLinks, false, roadLinkClickRef)
			}
		})

		// clean up on unmount
		return () => map.current.remove()
	}, [])

	useEffect(() => {
		if (draw) {
			map.current.addControl(drawFunc.current)
		}
	}, [draw])

	useEffect(() => {
		if (showPropertyInfoByClick && allPropertiesData) {
			showPropertyInfoByClickFunction(map, allPropertiesData, setPropertyDialogData, propertiesClickRef, true)

			showPropertyInfoByClickFunction(map, allPropertiesData, setPropertyDialogData, propertiesClickRef, false)
		} else {
			showPropertyInfoByClickFunction(map, allPropertiesData, setPropertyDialogData, propertiesClickRef, true)
		}
	}, [showPropertyInfoByClick, allPropertiesData])

	useEffect(() => {
		if (selectedCostCentersLinksValue) {
			// First we delete all costCenters roadLinks that doesnt match with current selected costCenters
			if (loading || error) return
			data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.map((cc1: CostCenter) => {
				if (selectedCostCentersLinksValue.some((cc2) => cc2.costCenterData.name === cc1.name)) {
					return
				} else {
					if (map.current.getLayer(`costCenterRoadLinks${cc1.name}`)) {
						map.current.removeLayer(`costCenterRoadLinks${cc1.name}`)
					}

					if (map.current.getSource(`costCenterRoadLinks${cc1.name}`)) {
						map.current.removeSource(`costCenterRoadLinks${cc1.name}`)
					}
				}
			})
			// then we update and create all roadLinks that we are select
			selectedCostCentersLinksValue.map((cc, index) => {
				selectedCostCentersLinks(map, cc.roadLinks, cc.costCenterIdColor, cc.costCenterData.name)
			})

			if (map.current.getLayer("clickRoadLink")) {
				if (map.current.getLayer(`costCenterRoadLinks${activeCostCenterName}`))
					map.current.moveLayer(`costCenterRoadLinks${activeCostCenterName}`, "clickRoadLink")
			}
		}

		if (showSelectedRoadLinks && !selectedCostCentersLinksValue) {
			selectedRoadLinksValues(map, selectedLinks)
		}

		if (mapClick) {
			mapClickFunction(map)
		}

		if (showSelectedProperties) {
			if (map.current._loaded) {
				propertyList.current.map((p: any) => {
					map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: p.plotId }, { hover: true })
				})
			}
		}

		if (selectProperties) {
			if (selectedProperties && map.current._loaded) {
				if (map.current._loaded) {
					map.current.removeFeatureState({
						source: "kipa",
						sourceLayer: "PalstanSijaintitiedot"
					})

					selectedProperties.map((p: any) => {
						map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: p.plotId }, { hover: true })
					})
				}
			} else {
				selectPropertiesFromMap(map, propertyList, setPropertyList, propertiesSelectRef, false)
			}
		}

		if (costCenterPropertyList && !selectProperties) {
			const setPropertiesInMap = (propertyList: any) => {
				map.current.removeFeatureState({
					source: "kipa",
					sourceLayer: "PalstanSijaintitiedot"
				})
				if (costCenterPropertyList) {
					let prevColor = "#a9fdac"
					propertyList.propertyList.map((p: any) => {
						map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: p.plotId }, { hover: true })
						prevColor = p.idColor
					})
				}
			}
			waitUntilMapLoaded(
				map,
				setPropertiesInMap,
				{
					propertyList: costCenterPropertyList
				},
				50
			)
		}
	}, [selectedRoadLinks, selectedCostCentersLinksValue, costCenterPropertyList, selectProperties])

	useEffect(() => {
		if (showPropertiesFromList || showSelectedPropertiesFromList) {
			const setPropertiesInMap = (propertyList: any) => {
				map.current.removeFeatureState({
					source: "kipa",
					sourceLayer: "PalstanSijaintitiedot"
				})
				if (showPropertiesFromList) {
					propertyList.propertyList.map((p: any) => {
						map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: p.plotId }, { hover: true })
					})
				}
			}
			waitUntilMapLoaded(
				map,
				setPropertiesInMap,
				{
					propertyList: showPropertiesFromList
				},
				20
			)
		}
	}, [showPropertiesFromList])

	useEffect(() => {
		if (
			(setCenterCoorinates && selectedRoadLinks && selectedRoadLinks.length != 0) ||
			(selectedCostCentersLinksValue && selectedCostCentersLinksValue.length != 0)
		) {
			let bounds = null
			if (selectedCostCentersLinksValue && selectedCostCentersLinksValue[0].roadLinks[0] != undefined) {
				bounds = new maplibregl.LngLatBounds(
					selectedCostCentersLinksValue[0].roadLinks[0].coordinates[0],
					selectedCostCentersLinksValue[0].roadLinks[0].coordinates[0]
				)

				for (const cc of selectedCostCentersLinksValue) {
					for (const lineString of cc.roadLinks) {
						for (const coord of lineString.coordinates) {
							if (bounds) bounds.extend(coord)
						}
					}
				}
				map.current.fitBounds(bounds, {
					padding: 20
				})
			}
			if (selectedRoadLinks && selectedRoadLinks.length > 0) {
				bounds = new maplibregl.LngLatBounds(selectedRoadLinks[0].coordinates[0], selectedRoadLinks[0].coordinates[0])
				for (const lineString of selectedRoadLinks) {
					for (const coord of lineString.coordinates) {
						if (bounds) bounds.extend(coord)
					}
				}
				map.current.fitBounds(bounds, {
					padding: 20
				})
			}
		}
	}, [selectedRoadLinks, selectedCostCentersLinksValue])

	useEffect(() => {
		if (selectProperties) {
			if (!_propertyList || _propertyList.length === 0) {
				return
			} else {
				costCenterPropertyVar({ properties: _propertyList })
			}
		}
	}, [_propertyList])

	useEffect(() => {
		if (editRoadLinks) {
			if (map.current.loaded() && updateCostCenterRoadLinks) {
				setupdateCostCenterRoadLinksState(updateCostCenterRoadLinks)
				setActiveRoadLinksSelectEffect(true)
			}
		} else {
			if (map.current._loaded) {
				getRoadLinkByClick(map, updateCostCenterRoadLinksState, setupdateCostCenterRoadLinksState, true, roadLinkClickRef, activeCostCenterColor)
				setFirstTimeStateUpdated(false)
				setActiveRoadLinksSelectEffect(false)
			}
		}
	}, [editRoadLinks])

	useEffect(() => {
		if (editProperties) {
			setUpdateCostCenterPropertiesState(costCenterPropertyList)
		} else {
			if (map.current._loaded) {
				selectPropertiesFromMap(map, updateCostCenterPropertiesState, setUpdateCostCenterPropertiesState, propertiesSelectRef, true)
				setFirstTimePropertiesStateUpdate(false)
			}
		}
	}, [editProperties])

	useEffect(() => {
		if (selectProperties) return
		if (!firstTimePropertiesStateUpdate && editProperties) {
			const loadingInitialPropertiesData = () => {
				if (map.current._loaded) {
					updateCostCenterPropertiesState.map((p: any) => {
						map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: p.plotId }, { hover: true })
					})
				}
			}
			waitUntilMapLoaded(map, loadingInitialPropertiesData)

			const selectPropertiesOnClickFromMap = (map: any, setSelectedPropertyState: any, ref?: any, setOff?: boolean) => {
				const selectPropertiesOnClick = (e: any) => {
					if (e.features.length > 0) {
						setSelectedPropertyState(e.features[0])
					}
				}
				if (setOff) {
					map.current.off("click", "PalstanSijaintitiedot", ref.current)
				} else {
					ref.current = selectPropertiesOnClick
					map.current.on("click", "PalstanSijaintitiedot", ref.current)
				}
			}

			selectPropertiesOnClickFromMap(map, setSelectedPropertyState, propertiesSelectRef, false)
			setFirstTimePropertiesStateUpdate(true)
		}

		if (editProperties && updateCostCenterPropertiesState) {
			costCenterPropertyVar({ properties: updateCostCenterPropertiesState })
		}
	}, [updateCostCenterPropertiesState])

	useEffect(() => {
		if (selectedPropertyState.current === null) return
		const index = updateCostCenterPropertiesState.findIndex(
			(p: any) => p.plotId === (selectedPropertyState.current ? selectedPropertyState.current.id : null)
		)
		if (index !== -1) {
			setShowSelectedPropertyPopupRemove(selectedPropertyState.current)
		} else {
			setShowSelectedPropertyPopupAdd(selectedPropertyState.current)
		}
	}, [_selectedPropertyState])

	useEffect(() => {
		if (!firstTimeStateUpdated && updateCostCenterRoadLinksState && updateCostCenterRoadLinksState.length > 0 && editRoadLinks) {
			getRoadLinkByClick(map, updateCostCenterRoadLinksState, setupdateCostCenterRoadLinksState, false, roadLinkClickRef, activeCostCenterColor)
			setFirstTimeStateUpdated(true)
			if (map.current.getLayer(`costCenterRoadLinks${activeCostCenterName}`)) {
				map.current.removeLayer(`costCenterRoadLinks${activeCostCenterName}`)
			}
		}

		if (activeRoadLinksSelectEffect) {
			costCenterLinksVar({ roadLinks: updateCostCenterRoadLinksState })
		}
		costCenterLinksVar({ roadLinks: updateCostCenterRoadLinksState })
	}, [updateCostCenterRoadLinksState, activeRoadLinksSelectEffect])

	useEffect(() => {
		if (updateCostCenterRoadLinks) return
		costCenterLinksVar({ roadLinks: selectedLinks })
	}, [selectedLinks])

	useEffect(() => {
		if (flyTo) {
			map.current.flyTo({
				center: [centerLat, centerLng],
				zoom: 7
			})
		} else {
			map.current.setCenter([centerLat, centerLng])
		}
	}, [centerLat])

	useEffect(() => {
		map.current.setZoom(zoom)
	}, [zoom])

	if (map.current?.loaded()) {
		map.current.on("draw.create", (e: any) => {
			updateArea(e)
		})
		map.current.on("draw.selectionchange", (e: any) => {
			selectArea(e)
		})
		map.current.on("draw.delete", updateArea)
		map.current.on("draw.update", (e: any) => {
			updateArea(e)
		})
	}

	const handleRemovePropertyInCostCenter = () => {
		const property: any = showSelectedPropertyPopupRemove
		map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: property.id }, { hover: false })

		setUpdateCostCenterPropertiesState((propertyList: any) => {
			propertyList = propertyList.filter((p: any) => p.plotId !== property.id)
			return propertyList
		})

		setShowSelectedPropertyPopupRemove(null)
	}

	const handleAddPropertyInCostCenter = () => {
		const property: any = showSelectedPropertyPopupAdd
		map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: property.id }, { hover: true })

		setUpdateCostCenterPropertiesState((propertyList: any) => {
			propertyList = propertyList.concat({
				mmlPropertyId: showSelectedPropertyPopupAdd.properties.kiinteistotunnuksenEsitysmuoto,
				plotId: showSelectedPropertyPopupAdd.id,
				propertyName: selectedPropertyName
			})
			return propertyList
		})

		setShowSelectedPropertyPopupAdd(null)
		setSelectedPropertyName("")
	}

	useEffect(() => {
		if (propertyDialogData?.open == true) {
			setOpenPropertyInfoDialog(true)
		} else {
			setOpenPropertyInfoDialog(false)
			setPropertyDialogData(null)
		}
	}, [propertyDialogData])

	return (
		<div className="map-container" id="map" onClick={void 0}>
			<div className="map" ref={mapContainerRef} id={id}>
				{filterBox == true ? <KipaLayersFilterBox map={map} /> : null}
				{mapSearchBox == true ? <MapSearchBox /> : null}{" "}
			</div>

			{editProperties ? (
				<>
					<Dialog open={showSelectedPropertyPopupAdd ? true : false}>
						<DialogContent>
							<Typography sx={{ fontSize: "1.2rem" }}>
								Haluatko Lisätä kiinteistön {showSelectedPropertyPopupAdd?.properties.kiinteistotunnuksenEsitysmuoto} tien osaan, osaksi
								tiekuntaa
							</Typography>
							<TextField
								sx={{
									display: "flex"
								}}
								label={"Tilan nimi"}
								value={selectedPropertyName}
								onChange={(e: { target: { value: string } }) => setSelectedPropertyName(e.target.value)}
							></TextField>
						</DialogContent>
						<DialogActions>
							<Button variant="outlined" onClick={() => setShowSelectedPropertyPopupAdd(null)}>
								Sulje
							</Button>
							<Button variant="contained" color="primary" onClick={handleAddPropertyInCostCenter}>
								Lisää
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog open={showSelectedPropertyPopupRemove ? true : false}>
						<DialogContent>
							<Typography sx={{ fontSize: "1.2rem" }}>
								Haluatko poistaa kiinteistön {showSelectedPropertyPopupRemove?.properties.kiinteistotunnuksenEsitysmuoto}. Samalla poistat myös
								kiinteistöön liittyvän yksikointi tiedot.
							</Typography>
						</DialogContent>
						<DialogActions>
							<Button variant="outlined" color="cancel" onClick={() => setShowSelectedPropertyPopupRemove(null)}>
								Sulje
							</Button>
							<Button variant="contained" color="close" onClick={handleRemovePropertyInCostCenter}>
								Poista
							</Button>
						</DialogActions>
					</Dialog>
				</>
			) : null}

			{propertyDialogData?.data && propertyDialogData.open ? (
				<Dialog
					open={openPropertyInfoDialog}
					onBackdropClick={() =>
						setPropertyDialogData({ data: propertyDialogData?.data, open: false, mmlPropertyId: propertyDialogData?.mmlPropertyId })
					}
				>
					<CustomDialogTitle>{propertyDialogData.mmlPropertyId}</CustomDialogTitle>
					<DialogContent>
						<MapPropertyInfoPopup />
					</DialogContent>
					<CustomDialogActions>
						<IconButton
							sx={{ position: "absolute", right: 5, top: 5 }}
							onClick={() =>
								setPropertyDialogData({ data: propertyDialogData?.data, open: false, mmlPropertyId: propertyDialogData?.mmlPropertyId })
							}
						>
							<CloseIcon />
						</IconButton>
					</CustomDialogActions>
				</Dialog>
			) : null}

			{(ShowDrawingsCalculations && drawPolygonCalculate) || (ShowDrawingsCalculations && drawLineCalculate) ? (
				<Paper elevation={6} sx={{ position: "absolute", padding: "10px", top: "200px", left: "50px", fontSize: "3rem" }}>
					{drawPolygonCalculate == 0 ? null : (
						<>
							<Typography sx={{ fontSize: "1.2rem", borderStyle: "none none solid none", borderWidth: "1px" }}>
								Valitun alueen pinta-ala: <br />
								{(drawPolygonCalculate / 10000).toFixed(2)} (ha) <br />
							</Typography>
						</>
					)}

					{drawLineCalculate == 0 ? null : (
						<>
							<Typography sx={{ fontSize: "1.2rem" }}>
								Piirretyn viivan pituus: <br />
								{drawLineCalculate.toFixed(2)} metriä
							</Typography>
						</>
					)}
				</Paper>
			) : null}
		</div>
	)
}

export { MapComponent }
