import React, { useEffect, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"

import { Paper, Typography, Tooltip, IconButton } from "@mui/material"
import userProfileStorage from "../../utils/providers/userProfileProvider/userProfileProvider"
import { toast } from "react-toastify"
import { Dialog } from "@mui/material"
import DialogCloseButton from "./DialogCloseButton"
import CustomDialogTitle from "./CustomDialogTitle"
import { DialogContent } from "@mui/material"
import CustomDialogActions from "./CustomDialogActions"
import { Button } from "@mui/material"
import { keyframes } from "@emotion/react"
import HelpIcon from "@mui/icons-material/Help"

const CREATE_SETUP_HELP = gql`
	mutation CreateSetUpHelp($input: CreateSetUpHelpInput!) {
		createSetUpHelp(input: $input) {
			success
		}
	}
`

const HelpWithInitialization = () => {
	const [createSetUpHelp, { loading }] = useMutation(CREATE_SETUP_HELP)
	const currentRC = userProfileStorage({ type: "getActiveRoadCooperative" })
	const [open, setOpen] = useState(false)
	const [showThankYouMessage, setShowThankYouMessage] = useState(false)

	const roadCooperativeName = currentRC.roadCooperative

	const handleSendSetUp = async () => {
		try {
			const response = await createSetUpHelp({ variables: { input: { roadCooperativeName } } })
			if (response.data.createSetUpHelp.success) {
				toast.success(response.data.createSetUpHelp.message)
				setOpen(false)
				setShowThankYouMessage(true)
				setTimeout(() => setShowThankYouMessage(false), 5000)
			} else {
				toast.error("Virhe pyynnön lähettämisessä: " + response.data.createSetUpHelp.message)
			}
		} catch (error: any) {
			toast.error("Virhe pyynnön lähettämisessä: " + error.message)
		}
	}

	const handleHelpClick = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const fadeInUp = keyframes`
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `

	const fadeOutDown = keyframes`
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100px);
      opacity: 0;
    }
  `
	const pulseAnimation = keyframes`
0% {
  transform: scale(1);
}
25% {
	transform: scale(1.17);
	}
50% {
	transform: scale(1.17);
}
75% {
	transform: scale(1.17);
	
}
100% {
  transform: scale(1);
}
`
	return (
		<>
			<Tooltip sx={{ marginLeft: "5px" }} title="Pyydä apua Tievahdin käyttöönottoon.">
				<IconButton sx={{ bottom: "2px", marginBottom: "-3px" }} onClick={handleHelpClick}>
					<HelpIcon color="secondary" sx={{ animation: `${pulseAnimation} 2s ease-in-out infinite` }} />
				</IconButton>
			</Tooltip>
			<Dialog open={open}>
				<DialogCloseButton closeFunction={handleClose} />
				<CustomDialogTitle>Pyydä apua Tievahdin käyttöönottoon</CustomDialogTitle>
				<DialogContent>
					Mikäli tarvitset apua Tievahdin käyttöönotossa, autamme mielellämme. Lisäämme puolestanne tiekunnan tien, kiinteistöt, osakkaat sekä yksiköt
					olemassa olevien tietojenne pohjalta. Autamme teitä pääsemään alkuun Tievahdin talous-osion kanssa, jota hyödyntämällä voitte hoitaa
					tiekuntanne kirjanpidon ja tiemaksujen laskutuksen. Käyttöönoton jälkeen käymme vielä yhdessä läpi ohjelman loputkin ominaisuudet, jonka
					tavoitteena on että pystyisitte jatkossa käyttämään Tievahtia itsenäisesti. Palvelun hinta on <b>250 € (alv 0 %)</b>.
				</DialogContent>
				<CustomDialogActions>
					<Button onClick={handleClose} color="cancel" variant="outlined">
						Sulje
					</Button>
					<Button onClick={handleSendSetUp} color="primary" variant="contained" disabled={loading}>
						{loading ? "Lähetetään..." : "Lähetä pyyntö"}
					</Button>
				</CustomDialogActions>
			</Dialog>
			{showThankYouMessage && (
				<Paper
					elevation={5}
					sx={{
						zIndex: 3,
						position: "fixed",
						left: "calc(50vw - 100px)",
						top: "calc(100vh - 130px)",
						width: "200px",
						padding: "25px",
						animation: `${fadeInUp} 1s, ${fadeOutDown} 1s 4s`
					}}
				>
					<Typography color="primary" sx={{ textAlign: "center" }}>
						Kiitos yhteydenotosta. Palaamme asiaan pian.
					</Typography>
				</Paper>
			)}
		</>
	)
}
export default HelpWithInitialization
