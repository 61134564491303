import React, { useEffect, useState } from "react"
import { IconButton } from "@mui/material"

import DeleteIcon from "@mui/icons-material/Delete"

import "./BulkImportTools.css"

interface MemberRowPropsType {
	member: any
	index: number
	setAllMembers: React.Dispatch<React.SetStateAction<any[]>>
	allCountries: Country[]
}

const MemberRow = React.memo(
	({ member, index, setAllMembers, allCountries }: MemberRowPropsType) => {
		// Deactivate window.alert
		window.alert = function () {
			null
		}

		const [updatedMemberData, setUpdatedMemberData] = useState<any>(member)

		useEffect(() => {
			setUpdatedMemberData(member)
		}, [])

		useEffect(() => {
			setAllMembers((prevData: any[]) => {
				const index = prevData.findIndex((member2) => member2.identifier === member.identifier)
				prevData[index] = updatedMemberData
				return [...prevData]
			})
		}, [updatedMemberData])

		const handleChangeEInvoice = (invoiceSendingType: any) => {
			if (invoiceSendingType == 3) {
				const elAddress: any = document.getElementById(`e-invoice-address${index}`)
				elAddress.disabled = false
				const elOperator: any = document.getElementById(`e-invoice-operator${index}`)
				elOperator.disabled = false
			} else {
				const elAddress: any = document.getElementById(`e-invoice-address${index}`)
				elAddress.disabled = true
				// setUpdatedMemberData({ ...updatedMemberData, eInvoiceAddress: "" })
				const elOperator: any = document.getElementById(`e-invoice-operator${index}`)
				elOperator.disabled = true
				// setUpdatedMemberData({ ...updatedMemberData, eInvoiceOperator: "" })
			}
		}

		const handleChangeMemberData = async (field: any, value: any) => {
			if (field == "invoiceSendingType") {
				handleChangeEInvoice(value)
				if (value == 3) {
					setUpdatedMemberData({ ...updatedMemberData, [field]: value })
				} else {
					setUpdatedMemberData({ ...updatedMemberData, [field]: value, eInvoiceOperator: "", eInvoiceAddress: "" })
				}
			} else {
				setUpdatedMemberData({ ...updatedMemberData, [field]: value })
			}
		}

		const handleDeleteMember = () => {
			setAllMembers((prevData) => prevData.filter((member2) => member2.identifier != member.identifier))
		}

		return (
			<>
				{/* {alert ? 
                <Alert severity={'error'} >{alert}</Alert>
            : null
            } */}
				<td className="bulk-cell icon-cell" onClick={handleDeleteMember}>
					<IconButton>
						<DeleteIcon />
					</IconButton>
				</td>
				<td className="bulk-cell">
					<input
						className="bulk-input"
						value={updatedMemberData.name}
						style={{ borderColor: updatedMemberData.name ? "" : "#e74c3c", border: updatedMemberData.name ? "0px" : "solid 1px #e74c3c" }}
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("name", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						className="bulk-input"
						value={updatedMemberData.contactPerson}
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("contactPerson", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						className="bulk-input"
						value={updatedMemberData.email}
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("email", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						className="bulk-input"
						value={updatedMemberData.telNumber}
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("telNumber", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						className="bulk-input"
						value={updatedMemberData.address}
						style={{ borderColor: updatedMemberData.address ? "" : "#e74c3c", border: updatedMemberData.address ? "0px" : "solid 1px #e74c3c" }}
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("address", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						className="bulk-input"
						value={updatedMemberData.postcode}
						style={{ borderColor: updatedMemberData.postcode ? "" : "#e74c3c", border: updatedMemberData.postcode ? "0px" : "solid 1px #e74c3c" }}
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("postcode", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						className="bulk-input"
						value={updatedMemberData.postalDistrict}
						style={{
							borderColor: updatedMemberData.postalDistrict ? "" : "#e74c3c",
							border: updatedMemberData.postalDistrict ? "0px" : "solid 1px #e74c3c"
						}}
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("postalDistrict", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						className="bulk-input"
						value={updatedMemberData.businessId}
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("businessId", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<select
						value={updatedMemberData.countryCode}
						className="bulk-input bulk-select"
						name="countryCode"
						id="countryCode"
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("countryCode", e.target.value)}
					>
						{allCountries.map((country: Country) => (
							<option key={country.countryCode.toString()} value={country.countryCode}>
								{country.country} - {country.countryCode}
							</option>
						))}
					</select>
				</td>
				<td className="bulk-cell">
					<select
						className="bulk-input bulk-select"
						name="languageCode"
						id="languageCode"
						value={updatedMemberData.languageCode}
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("languageCode", e.target.value)}
					>
						<option value={"fi"}>Suomi</option>
						<option value={"sv"}>Ruotsi</option>
						<option value={"en"}>Englanti</option>
					</select>
				</td>
				<td className="bulk-cell">
					<select
						className="bulk-input bulk-select"
						name="invoiceSendingType"
						id="invoiceSendingType"
						value={updatedMemberData.invoiceSendingType}
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("invoiceSendingType", e.target.value)}
					>
						<option value={1}>Kirje</option>
						<option value={2}>Sähköposti</option>
						<option disabled={!updatedMemberData.businessId} value={3}>
							Verkkolasku
						</option>
					</select>
				</td>
				<td className="bulk-cell">
					<select
						className="bulk-input bulk-select"
						name="meetingInviteType"
						id="meetingInviteType"
						value={updatedMemberData.meetingInviteType}
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("meetingInviteType", e.target.value)}
					>
						<option value={1}>Kirje</option>
						<option value={2}>Sähköposti</option>
					</select>
				</td>
				<td className="bulk-cell">
					<input
						id={`e-invoice-address${index}`}
						disabled={updatedMemberData.invoiceSendingType !== 3 ? true : false}
						className="bulk-input"
						value={updatedMemberData.eInvoiceAddress}
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("eInvoiceAddress", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						id={`e-invoice-operator${index}`}
						disabled={updatedMemberData.invoiceSendingType !== 3 ? true : false}
						className="bulk-input"
						value={updatedMemberData.eInvoiceOperator}
						onChange={(e: { target: { value: string } }) => handleChangeMemberData("eInvoiceOperator", e.target.value)}
					/>
				</td>
			</>
		)
	},
	(prev, next) => {
		return true
	}
)

MemberRow.displayName = "MemberRow"

export default MemberRow
