import React, { useEffect, useRef, useState } from "react"
import { Box, TextField, InputAdornment, Tooltip, Paper, Stack, Typography } from "@mui/material"

import SearchIcon from "@mui/icons-material/Search"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"

import maplibregl from "maplibre-gl"

import { useMapState } from "../../utils/providers/mapProvider/mapProvider"
import Draggable from "react-draggable"
import config from "../../utils/config"
import { gql, useLazyQuery, useQuery } from "@apollo/client"

const GET_PROPERTIES = gql`
	query GetCostCentersAndProperties {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					propertiesByCostCenterId {
						nodes {
							mmlPropertyId
							plotId
							propertyName
							propertyTag
							establishmentsByPropertyId {
								nodes {
									roadCooperativeMemberByRoadCooperativeMemberId {
										name
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

type PropertySearchBoxProps = {
	map: any
}

const PropertySearchBox = ({ map }: PropertySearchBoxProps) => {
	const { state, dispatch } = useMapState()

	const [searchTerm, setSearchTerm] = useState("")
	const [allProperties, setAllProperties] = useState<any>([])
	const [searchedProperties, setSearchedProperties] = useState<any>([])
	const [selectedProperty, setSelectedProperty] = useState<any>(null)

	const lastFetchTimeRef = useRef(0)

	const [loadProperties, { called, data }] = useLazyQuery(GET_PROPERTIES, {
		fetchPolicy: "network-only",
		onCompleted: (data) => {
			console.log("data fetched")
		},
		onError: (error) => {
			console.error("Error fetching data: ", error)
		}
	})

	useEffect(() => {
		if (!data?.roadCooperativeWithJWT?.costCentersByRoadCooperativeId?.nodes) return
		const costCenters = data?.roadCooperativeWithJWT?.costCentersByRoadCooperativeId?.nodes
		const properties = []
		for (let i = 0; i < costCenters.length; i++) {
			const costCenter = costCenters[i]
			const costCenterProperties = costCenter?.propertiesByCostCenterId?.nodes
			properties.push(...costCenterProperties)
		}
		setAllProperties(properties)
	}, [data])

	useEffect(() => {
		const RCInfoPaperId = "roadCooperativeInfoPaper"
		const propertySearchBoxId = "propertySearchBox"
		const RCInfoPaperEl = document.getElementById(RCInfoPaperId)
		const propertySearchBoxEl = document.getElementById(propertySearchBoxId)

		if (RCInfoPaperEl && propertySearchBoxEl) {
			const RCInfoPaperElHeight = RCInfoPaperEl.offsetHeight
			propertySearchBoxEl.style.top = RCInfoPaperElHeight + 120 + "px"
		}
	}, [])

	const setEmptyPropertyFeature = () => {
		setSelectedProperty(null)
		const geoJsonData = {
			type: "FeatureCollection",
			features: []
		}
		// päivitetään karttakerroksen dataa
		map.current.getSource(`search-property-source`).setData(geoJsonData)
	}

	const handlePropertySearch = (value: string) => {
		setEmptyPropertyFeature()
		setSearchTerm(value)
		if (value === "") {
			setSearchedProperties([])
			return
		}
		const filteredProperties = []
		for (let i = 0, len = allProperties.length; len > i; i++) {
			const property = allProperties[i]
			const namesString = property.establishmentsByPropertyId.nodes.reduce((acc: any, est: any) => {
				if (est.roadCooperativeMemberByRoadCooperativeMemberId) {
					acc += " " + est.roadCooperativeMemberByRoadCooperativeMemberId.name.toLowerCase()
				}
				return acc
			}, "")

			if (
				namesString.includes(value.toLowerCase()) ||
				property?.mmlPropertyId?.includes(value) ||
				(property.propertyName && property.propertyName.toLowerCase().includes(value.toLowerCase()))
			) {
				filteredProperties.push(property)
			}
		}
		setSearchedProperties(filteredProperties)
	}

	const handlePropertyClick = async (property: any) => {
		if (selectedProperty?.plotId === property.plotId) {
			setEmptyPropertyFeature()
			return
		}
		console.log(property)

		try {
			const MML_API_KEY = config.MML_API_KEY
			const plotId = property.plotId
			// Haetaan maanmittauslaitokselta kiinteistön koordinaatit
			const propertyDataFromMML = await fetch(
				`https://avoin-paikkatieto.maanmittauslaitos.fi/kiinteisto-avoin/simple-features/v3/collections/PalstanSijaintitiedot/items/${plotId}?api-key=${MML_API_KEY}`
			)
			const data = await propertyDataFromMML.json()
			const coordinates = data.geometry.coordinates

			const bounds = new maplibregl.LngLatBounds()
			for (let i = 0, len = coordinates.length; len > i; i++) {
				const polygon = coordinates[i]
				for (let i2 = 0, len = polygon.length; len > i2; i2++) {
					bounds.extend(polygon[i2])
				}
			}
			map.current.fitBounds(bounds, {
				// padding: 150,
				zoom: map.current.getZoom()
			})
			// Luodaan koordinaattidatasta geoJSON feature
			const geoJsonData = {
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						properties: { id: property.plotId },
						id: property.plotId,
						geometry: {
							type: "MultiLineString",
							coordinates: coordinates
						}
					}
				]
			}
			// päivitetään karttakerroksen dataa
			map.current.getSource(`search-property-source`).setData(geoJsonData)
			setSelectedProperty(property)
		} catch (error) {
			console.error(" -- ERROR IN MML DATA FETCH: ", error)
		}
	}

	const handleFocus = () => {
		const currentTime = Date.now()
		const timeSinceLastFetch = currentTime - lastFetchTimeRef.current

		if (timeSinceLastFetch > 8000) {
			loadProperties()
			lastFetchTimeRef.current = Date.now()
		} else {
			console.log("8 sek cd")
		}
	}

	if (state.allProperties.length === 0 && !called) return null

	return (
		<div id="propertySearchBox" style={{ position: "absolute", left: 4, right: 0, top: 0, zIndex: 2 }}>
			<Draggable
				defaultPosition={{ x: 0, y: 50 }}
				grid={[2, 2]}
				handle=".handle"
				bounds={{ left: 0, top: -250, right: window.innerWidth - 190, bottom: window.innerHeight - 350 }}
			>
				<Paper sx={{ zIndex: 5, maxWidth: "310px", padding: "10px", position: "absolute" }}>
					<Stack direction="row" sx={{ alignItems: "center" }}>
						<Tooltip title="Liikuta laatikkoa vetämällä">
							<DragIndicatorIcon fontSize="large" sx={{ cursor: "grab" }} className={"handle"} />
						</Tooltip>
						<TextField
							sx={{ width: "300px" }}
							label="Etsi kiinteistöjä"
							id="home-page-search-property"
							value={searchTerm}
							onFocus={handleFocus}
							onChange={(e: { target: { value: string } }) => handlePropertySearch(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								)
							}}
						/>
					</Stack>
					<Box sx={{ maxHeight: "300px", overflowY: "scroll" }}>
						{searchedProperties.map((property: any) => (
							<Box
								key={property.id}
								onClick={() => handlePropertyClick(property)}
								sx={{
									cursor: "pointer",
									padding: "5px",
									borderRadius: "40px",
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									background: selectedProperty?.plotId === property.plotId ? "#ffee0085" : "transparent",
									border: selectedProperty?.plotId === property.plotId ? "solid #ffee00" : "none"
								}}
							>
								<Typography
									sx={{
										paddingLeft: "10px",
										maxWidth: "300px",
										fontWeight: "400",
										fontSize: "0.8rem",
										textOverflow: "ellipsis",
										overflow: "hidden"
									}}
								>
									{property.mmlPropertyId} {property.propertyName}
								</Typography>
							</Box>
						))}
					</Box>
				</Paper>
			</Draggable>
		</div>
	)
}

export default PropertySearchBox
