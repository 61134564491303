import waitUntilMapLoaded from "./waitUntilMapLoaded"

type returnType = Array<any>

const selectPropertiesFromMap = (map: any, propertyList: any, setPropertyList: any, ref?: any, setOff?: boolean) => {
	// if(propertyList === undefined) {
	//   setPropertyList([])
	// }
	// console.log(propertyList.current, "-------------- INITIAL PROPERTY LIST --------------- ")

	if (!map) return

	const loadingInitialProertiesData = () => {
		// console.log(" -- JJOOO 1 ----- ")
		if (!propertyList.current) return
		if (map.current._loaded) {
			propertyList.current.map((p: any) => {
				// console.log(p, "  --- PROPERTY ------- ")
				map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: p.plotId }, { hover: true })
			})
		}
	}

	waitUntilMapLoaded(map, loadingInitialProertiesData)

	const selectPropertiesOnClick = (e: any) => {
		// console.log(propertyList.current, " ------ LISTA KIINTEISTÖISTÄ ---------- ")

		if (propertyList.current === undefined) return
		let tempPropertyList

		// console.log(e.features, " --------------- tämä on palstan dataa ---------------- ")

		if (e.features.length > 0) {
			// console.log(" --- properties 1 --- ")

			const index = propertyList.current.findIndex((p: any) => p.plotId === e.features[0].id)
			if (index !== -1) {
				// console.log(" --- properties 2 --- ")
				tempPropertyList = propertyList.current.filter((p: any) => p.plotId !== e.features[0].id)
				map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: e.features[0].id }, { hover: false })
			} else {
				tempPropertyList = propertyList.current.concat({
					mmlPropertyId: e.features[0].properties.kiinteistotunnuksenEsitysmuoto,
					plotId: e.features[0].id
				})
				// console.log(" --- properties 3 --- ")
				map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: e.features[0].id }, { hover: true })
			}
		}

		// console.log(tempPropertyList, " ------------- TEMP PROPERTY LIST -------------- ")

		setPropertyList(tempPropertyList)
	}

	if (setOff) {
		setPropertyList([])
		// console.log(setOff," ------------ SETTING OFF SELECT PROPERTIES ON CLICK ------------------ ")

		map.current.off("click", "PalstanSijaintitiedot", ref.current)
	} else {
		// console.log(setOff," ------------ SETTING ON SELECT PROPERTIES ON CLICK ------------------ ")
		ref.current = selectPropertiesOnClick
		map.current.on("click", "PalstanSijaintitiedot", ref.current)
	}
}

export default selectPropertiesFromMap
