import { TextField, Container, Stack, ToggleButtonGroup, ToggleButton, Grid, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import React, { ReactElement, useEffect, useState } from "react"
import { useCreateUserState } from "../../../utils/providers/CreateUserProvider"

import BusinessIcon from "@mui/icons-material/Business"
import PersonIcon from "@mui/icons-material/Person"
import { MenuItem } from "@mui/material"
import { Box } from "@mui/material"
import { ApolloQueryResult, gql, OperationVariables, useMutation, useQuery } from "@apollo/client"
import userProfileStorage from "../../../utils/providers/userProfileProvider/userProfileProvider"
import { Alert } from "@mui/material"
import { toast } from "react-toastify"
import { Button } from "@mui/material"
import { getAllCountries } from "../../../network/dataServices/generalDataService"
import { Typography } from "@mui/material"

const GET_ALL_ROAD_USAGE_UNITS = gql`
	query MyQuery2 {
		roadCooperativeWithJWT {
			roadUsageUnitsByRoadCooperativeId {
				nodes {
					roadUsageUnit
					roadUsageUnitType
					weight
				}
			}
		}
	}
`

const CREATE_ROAD_COOPERATIVE_MEMBER_AND_BILLING_INFO = gql`
	mutation CreateExternalOperator($isExternalOperator: Boolean!, $billingInformation: MemberBillingInformationInput!, $member: RoadCooperativeMemberInput!) {
		createMemberOrExternalOperator(input: { isExternalOperator: $isExternalOperator, billingInformation: $billingInformation, member: $member })
	}
`

const CreateNewUserDialog = ({
	setShowCreateUserForm,
	refetchRCGeoJson,
	pulseFunctionWhenDataCreated
}: {
	setShowCreateUserForm: React.Dispatch<React.SetStateAction<boolean>>
	refetchRCGeoJson: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
	pulseFunctionWhenDataCreated: () => void
}) => {
	const { state, dispatch } = useCreateUserState()

	const [allCountries, setAllCountries] = useState<Country[]>([])

	const [alert, setAlert] = useState("")

	const [createRoadCooperativeMember] = useMutation(CREATE_ROAD_COOPERATIVE_MEMBER_AND_BILLING_INFO)

	const { refetch, data: roadUsageUnits, loading: roadUsageUnitsLoading, error: roadUsageUnitsError } = useQuery(GET_ALL_ROAD_USAGE_UNITS)

	useEffect(() => {
		getAllCountries().then((data) => {
			console.log(data)
			if (data) setAllCountries(data)
		})
	}, [])

	const handleChangeMemberType = (v: string | null) => {
		if (!v) {
			return
		} else {
			dispatch({ type: "setMemberType", payload: v })
		}
	}

	const checkAllMemberParameters = () => {
		console.log(state)

		let alertText = ""
		if (state.memberType === "yksityishenkilö") {
			if (!state.personName) {
				alertText = "Nimi puuttuu. "
			} else if (state.personName?.length > 100) {
				alertText = alertText + "Nimi on liian pitkä. "
			}
			if (state.invoiceSendingType == 3) {
				alertText = alertText + "Laskun toimitustapa yksityishenkilöllä ei voi olla verkkolasku. "
			}
		} else {
			if (!state.personName) {
				alertText = "Yrityksen nimi puuttuu. "
			} else if (state.personName?.length > 100) {
				alertText = alertText + "Nimi on liian pitkä. "
			}
			if (!state.invoiceSendingType) {
				alertText = alertText + "Laskun toimitustapa puuttuu. "
			}
			if (!state.meetingInviteType) {
				alertText = alertText + "Kokouskutsun toimitustapa puuttuu. "
			}
			if (state.invoiceSendingType == 3) {
				if (!state.eInvoiceAddress) {
					alertText = alertText + "Verkkolaskuosoite puuttuu. "
				}
				if (!state.eInvoiceOperator) {
					alertText = alertText + "Operaattoritunnus puuttuu. "
				}
			}
			if (!state.businessId) {
				alertText = alertText + "Y-tunnus puuttuu. "
			}
		}
		if (!state.address) {
			alertText = alertText + "Postiosoite puuttuu. "
		}
		if (!state.postalDistric) {
			alertText = alertText + "Postitoimipaikka puuttuu. "
		}
		if (!state.postalCode) {
			alertText = alertText + "Postinumero puuttuu."
		} else if (state.postalCode?.length > 5) {
			alertText = alertText + "Postinumerossa on liian monta merkkiä."
		}
		if (alertText != "") {
			setAlert(alertText)
			setTimeout(() => {
				setAlert("")
			}, 5000)
			return false
		}
		return true
	}

	const handleCreateMember = async () => {
		if (checkAllMemberParameters()) {
			const userRoadCooperative = userProfileStorage({ type: "getActiveRoadCooperative" })
			createRoadCooperativeMember({
				variables: {
					isExternalOperator: false,
					billingInformation: {
						billingAddress: state.address,
						businessId: state.businessId,
						contactPerson: state.contactPerson,
						invoiceSendingType: typeof state.invoiceSendingType === "string" ? parseInt(state.invoiceSendingType) : state.invoiceSendingType,
						meetingInviteType: typeof state.meetingInviteType === "string" ? parseInt(state.meetingInviteType) : state.meetingInviteType,
						phoneNumber: state.personTel,
						postalDistrict: state.postalDistric,
						postcode: state.postalCode,
						email: state.email ? state.email : null,
						countryCode: state.countryCode,
						languageCode: state.languageCode,
						eInvoiceAddress: state.eInvoiceAddress,
						eInvoiceOperator: state.eInvoiceOperator
					},
					member: {
						name: state.personName,
						userEmail: state.email ? state.email : null
					}
				},
				onCompleted: () => {
					toast.success("Osakas luotu onnistuneesti!")
					refetchRCGeoJson()
					pulseFunctionWhenDataCreated()
					setShowCreateUserForm(false)
				},
				onError: () => {
					toast.error("Osakkaan luonti epäonnistui!")
				}
			})
		}
	}

	return (
		<div>
			<Container maxWidth="xl">
				<Button color="primary" onClick={handleCreateMember} variant="contained" sx={{ position: "absolute", bottom: "25px", right: "25px" }}>
					Tallenna
				</Button>

				{alert ? <Alert severity="error">{alert}</Alert> : null}
				<Stack marginTop={"40px"} direction="column" justifyContent="center" alignItems="center" spacing={2}>
					<ToggleButtonGroup
						value={state.memberType}
						color="primary"
						onChange={(e: ReactElement, v: string | null) => handleChangeMemberType(v)}
						exclusive
						size="large"
						aria-label="recipient type"
					>
						<ToggleButton value="yritys" fullWidth sx={{ alignItems: "space-between", maxWidth: 300 }}>
							Yritys
							<BusinessIcon sx={{ marginLeft: 2, fontSize: 32 }} />
						</ToggleButton>
						<ToggleButton value="yksityishenkilö" fullWidth sx={{ alignItems: "space-between", maxWidth: 300 }}>
							<PersonIcon sx={{ marginRight: 2, fontSize: 32 }} />
							Henkilö
						</ToggleButton>
					</ToggleButtonGroup>
					<Grid>
						{state.memberType === "yritys" ? <OrganisationUserInfo allCountries={allCountries} /> : <PersonUserInfo allCountries={allCountries} />}
					</Grid>

					<Grid>
						<Stack direction={{ xs: "column", sm: "row" }} justifyContent="center" alignItems="flex-start" spacing={2} sx={{ width: "100%" }}>
							<Grid>
								<br />
								<div>
									<FormLabel sx={{ fontWeight: "700" }}>Laskun toimitustapa</FormLabel>
									<RadioGroup
										row
										value={state.invoiceSendingType}
										onChange={(e: { target: { value: number } }) => dispatch({ type: "setInvoiceSendingType", payload: e.target.value })}
										name="row-radio-buttons-group"
									>
										<FormControlLabel value={1} control={<Radio />} label={<Typography sx={{ fontWeight: "500" }}>Kirje</Typography>} />
										<FormControlLabel
											value={2}
											control={<Radio />}
											label={<Typography sx={{ fontWeight: "500" }}>Sähköposti</Typography>}
										/>
										<FormControlLabel
											value={3}
											control={<Radio />}
											disabled={state.memberType === "yksityishenkilö"}
											label={<Typography sx={{ fontWeight: "500" }}>Verkkolasku</Typography>}
										/>
									</RadioGroup>

									{state.invoiceSendingType == 3 && state.memberType === "yritys" ? (
										<Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" sx={{ maxWidth: "352.14px" }}>
											<TextField
												inputProps={{
													maxLength: 30
												}}
												sx={{ maxWidth: "170px", marginTop: "-8px", marginBottom: "10px" }}
												label="Verkkolaskuosoite"
												variant="standard"
												onChange={(e: any) => dispatch({ type: "setEInvoiceAddress", payload: e.target.value })}
												value={state.eInvoiceAddress}
												required
											/>
											<TextField
												inputProps={{
													maxLength: 30
												}}
												sx={{ maxWidth: "170px", marginTop: "-8px", marginBottom: "10px" }}
												label="Operaattoritunnus"
												variant="standard"
												onChange={(e: any) => dispatch({ type: "setEInvoiceOperator", payload: e.target.value })}
												value={state.eInvoiceOperator}
												required
											/>
										</Grid>
									) : null}
								</div>
								<br />
								<FormLabel sx={{ fontWeight: "700" }}>Kokouskutsun toimitustapa</FormLabel>
								<RadioGroup
									row
									value={state.meetingInviteType}
									name="row-radio-buttons-group"
									onChange={(e: any) => dispatch({ type: "setMeetingInviteType", payload: e.target.value })}
								>
									<FormControlLabel value={1} control={<Radio />} label={<Typography sx={{ fontWeight: "500" }}>Kirje</Typography>} />
									<FormControlLabel value={2} control={<Radio />} label={<Typography sx={{ fontWeight: "500" }}>Sähköposti</Typography>} />
								</RadioGroup>{" "}
								<br />
							</Grid>
						</Stack>
					</Grid>
				</Stack>
			</Container>
		</div>
	)
}

const OrganisationUserInfo = ({ allCountries }: { allCountries: Country[] }) => {
	const { state, dispatch } = useCreateUserState()

	return (
		<>
			<Box sx={{ textAlign: "center" }}>
				<Grid container xs={12} direction={{ xs: "column", sm: "row" }} justifyContent="center" alignItems="stretch" spacing={2} sx={{ width: "100%" }}>
					<Grid item xs={12} sm={6}>
						<TextField
							inputProps={{
								maxLength: 100
							}}
							label="Yrityksen nimi"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setPersonName", payload: e.target.value })}
							value={state.personName}
							required
						/>
						<br />
						<TextField
							inputProps={{
								maxLength: 100
							}}
							type="email"
							label="Sähköposti"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setEmail", payload: e.target.value })}
							value={state.email}
						/>
						<br />
						<TextField
							inputProps={{
								maxLength: 50
							}}
							label="Puhelinnumero"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setPersonTel", payload: e.target.value })}
							value={state.personTel}
						/>{" "}
						<br />
						<TextField
							inputProps={{
								maxLength: 50
							}}
							label="Y-tunnus"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setBusinessId", payload: e.target.value })}
							value={state.businessId}
							required
						/>
						<br />
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							inputProps={{
								maxLength: 100
							}}
							label="Postiosoite"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setAddress", payload: e.target.value })}
							value={state.address}
							required
						/>
						<br />
						<TextField
							inputProps={{
								maxLength: 100
							}}
							label="Postinumero"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setPostalCode", payload: e.target.value })}
							value={state.postalCode}
							required
						/>
						<br />
						<TextField
							inputProps={{
								maxLength: 100
							}}
							label="Postitoimipaikka"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setPostalDistric", payload: e.target.value })}
							value={state.postalDistric}
							required
						/>
						<br />
						<TextField
							inputProps={{
								maxLength: 100
							}}
							label="Yhteyshenkilö"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setContactPerson", payload: e.target.value })}
							value={state.contactPerson}
						/>
						<br />
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							label="Maa"
							onChange={(e: any) => dispatch({ type: "setCountryCode", payload: e.target.value })}
							value={state.countryCode}
							variant="standard"
							select
							fullWidth
							sx={{ maxWidth: "190px" }}
						>
							{allCountries.map((country: Country) => {
								return (
									<MenuItem key={country.countryCode.toString()} value={country.countryCode}>
										{country.country} - {country.countryCode}
									</MenuItem>
								)
							})}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							label="Kieli"
							onChange={(e: any) => dispatch({ type: "setLanguageCode", payload: e.target.value })}
							value={state.languageCode}
							variant="standard"
							select
							fullWidth
							sx={{ maxWidth: "190px" }}
						>
							<MenuItem value={"fi"}>Suomi</MenuItem>
							<MenuItem value={"sv"}>Ruotsi</MenuItem>
							<MenuItem value={"en"}>Englanti</MenuItem>
						</TextField>
					</Grid>
				</Grid>
			</Box>
		</>
	)
}

const PersonUserInfo = ({ allCountries }: { allCountries: Country[] }) => {
	const { state, dispatch } = useCreateUserState()

	return (
		<div>
			<Box sx={{ textAlign: "center" }}>
				<Grid container xs={12}>
					<Grid item xs={12} sm={6}>
						<TextField
							inputProps={{
								maxLength: 100
							}}
							label="Nimi"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setPersonName", payload: e.target.value })}
							value={state.personName}
							required
						/>
						<br />
						<TextField
							inputProps={{
								maxLength: 100
							}}
							type="email"
							label="Sähköposti"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setEmail", payload: e.target.value })}
							value={state.email}
						/>
						<br />
						<TextField
							inputProps={{
								maxLength: 50
							}}
							label="Puhelinnumero"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setPersonTel", payload: e.target.value })}
							value={state.personTel}
						/>
						<br />
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							inputProps={{
								maxLength: 100
							}}
							label="Postiosoite"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setAddress", payload: e.target.value })}
							value={state.address}
							required
						/>
						<br />
						<TextField
							inputProps={{
								maxLength: 100
							}}
							label="Postinumero"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setPostalCode", payload: e.target.value })}
							value={state.postalCode}
							required
						/>
						<br />
						<TextField
							inputProps={{
								maxLength: 100
							}}
							label="Postitoimipaikka"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setPostalDistric", payload: e.target.value })}
							value={state.postalDistric}
							required
						/>
						<br />
						<TextField
							inputProps={{
								maxLength: 100
							}}
							label="Yhteyshenkilö"
							variant="standard"
							onChange={(e: any) => dispatch({ type: "setContactPerson", payload: e.target.value })}
							value={state.contactPerson}
						/>
						<br />
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							label="Maa"
							onChange={(e: any) => dispatch({ type: "setCountryCode", payload: e.target.value })}
							value={state.countryCode}
							variant="standard"
							select
							fullWidth
							sx={{ maxWidth: "190px" }}
						>
							{allCountries.map((country: Country) => {
								return (
									<MenuItem key={country.countryCode.toString()} value={country.countryCode}>
										{country.country} - {country.countryCode}
									</MenuItem>
								)
							})}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							label="Kieli"
							onChange={(e: any) => dispatch({ type: "setLanguageCode", payload: e.target.value })}
							value={state.languageCode}
							variant="standard"
							select
							fullWidth
							sx={{ maxWidth: "190px" }}
						>
							<MenuItem value={"fi"}>Suomi</MenuItem>
							<MenuItem value={"sv"}>Ruotsi</MenuItem>
							<MenuItem value={"en"}>Englanti</MenuItem>
						</TextField>
					</Grid>
				</Grid>
			</Box>
		</div>
	)
}

export default CreateNewUserDialog
