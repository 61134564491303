import React, { ReactElement, useEffect, useState } from "react"

import { gql, useLazyQuery, useMutation } from "@apollo/client"
import {
	Button,
	DialogContent,
	Stack,
	ToggleButtonGroup,
	ToggleButton,
	Grid,
	Typography,
	FormControl,
	Alert,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	MenuItem,
	TextField,
	useMediaQuery,
	Tooltip,
	IconButton
} from "@mui/material"
import OrganisationUserInfo from "../RoadCooperative/OrganisationUserInfo"
import PersonUserInfo from "../RoadCooperative/PersonUserInfo"
import BusinessIcon from "@mui/icons-material/Business"
import PersonIcon from "@mui/icons-material/Person"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import CheckIcon from "@mui/icons-material/Check"
import InfoIcon from "@mui/icons-material/Info"

import { checkMemberParameters, checkRCParameters } from "../RoadCooperative/utils/CheckMemberParameters"
import authToken from "../../utils/authToken"
import userProfileStorage from "../../utils/providers/userProfileProvider/userProfileProvider"
import CustomDialogTitle from "../reusables/CustomDialogTitle"
import CustomDialogActions from "../reusables/CustomDialogActions"
import DialogCloseButton from "../reusables/DialogCloseButton"
import customTheme from "../../utils/customTheme/muiTheme"
import { getAllCountries } from "../../network/dataServices/generalDataService"
import MunicipalitySelect from "../reusables/MunicipalityList"
import { pulseAnimation } from "../../utils/animations"
import LinkButton from "../reusables/LinkButton"

const SET_ID_TO_TOKEN = gql`
	query MyQuery($token: String, $id: String) {
		getTokenFromRoadCooperativeId(input: { token: $token, id: $id }) {
			token
		}
	}
`

const SET_ID_TO_TOKEN_EXTERNAL_OPERATOR = gql`
	query MyQuery($token: String, $id: String) {
		getTokenFromRoadCooperativeIdExternalOperator(input: { token: $token, id: $id }) {
			token
		}
	}
`

const CREATE_ROAD_COOPERATIVE_FOR_MEMBER = gql`
	mutation MyMutation(
		$roadCooperative: RoadCooperativeInput!
		$roadCooperativeMember: RoadCooperativeMemberInput!
		$roadCooperativeUserBillingInformation: MemberBillingInformationInput!
	) {
		createRoadCooperativeForMember(
			input: {
				roadCooperative: $roadCooperative
				roadCooperativeMember: $roadCooperativeMember
				roadCooperativeUserBillingInformation: $roadCooperativeUserBillingInformation
			}
		) {
			roadCooperative {
				id
			}
		}
	}
`

const CREATE_ROAD_COOPERATIVE_FOR_EXTERNAL_OPERATOR = gql`
	mutation MyMutation(
		$roadCooperative: RoadCooperativeInput!
		$roadCooperativeExternalOperator: RoadCooperativeExternalOperatorInput!
		$roadCooperativeExternalOperatorBillingInformation: MemberBillingInformationInput!
	) {
		createRoadCooperativeForExternalOperator(
			input: {
				roadCooperative: $roadCooperative
				roadCooperativeExternalOperator: $roadCooperativeExternalOperator
				roadCooperativeExternalOperatorBillingInformation: $roadCooperativeExternalOperatorBillingInformation
			}
		) {
			roadCooperative {
				id
			}
		}
	}
`

const emptyRoadCooperative = {
	RCName: "",
	RCBusinessId: "",
	RCPermissionUnit: "",
	RCRole: "",
	memberType: "yksityishenkilö",
	invoiceSendingType: 0,
	meetingInviteType: 0,
	name: "",
	email: "",
	companyName: "",
	address: "",
	postalDistrict: "",
	postalCode: "",
	countryCode: "FI",
	languageCode: "fi",
	contactPerson: "",
	telNumber: "",
	eInvoiceAddress: "",
	eInvoiceOperator: "",
	businessId: "",
	municipality: ""
}

const CreateRCDialog = ({ setCreateNewRC }: any) => {
	const [alert, setAlert] = useState("")
	const [allCountries, setAllCountries] = useState<Country[]>([])
	const [roadCooperative, setRoadCooperative] = useState(emptyRoadCooperative)
	const user = userProfileStorage({ type: "getUserProfile" })
	const rcStorage = userProfileStorage({ type: "getActiveRoadCooperative" })
	const [step, setStep] = useState<"rcInfo" | "personInfo">("rcInfo")
	const [RCalert, setRCAlert] = useState("")
	const [permission, setPermission] = useState("")

	const largeScreen = useMediaQuery(customTheme.customTheme.breakpoints.up("xl"))
	const xsScreen = useMediaQuery(customTheme.customTheme.breakpoints.up("xs"))

	const [setNewTokenId] = useLazyQuery(SET_ID_TO_TOKEN, {
		onCompleted: (data: any) => {
			console.log(data, " ----------- NEW TOKEN DATA ------------")
			authToken.setToken(data.getTokenFromRoadCooperativeId.token)
			console.log(data.getTokenFromRoadCooperativeId.token, " --- NEW TOKEN IN COOKIE ---")
			window.location.reload()
		}
	})

	const [setNewTokenIdExternalOperator] = useLazyQuery(SET_ID_TO_TOKEN_EXTERNAL_OPERATOR, {
		onCompleted: (data: any) => {
			console.log(data)
			authToken.setToken(data.getTokenFromRoadCooperativeIdExternalOperator.token)
			console.log(data.getTokenFromRoadCooperativeIdExternalOperator.token, " --- NEW TOKEN IN COOKIE ---")
			window.location.reload()
		}
	})

	const [createRoadCooperativeForMember] = useMutation(CREATE_ROAD_COOPERATIVE_FOR_MEMBER, {
		onCompleted: async (data: any) => {
			console.log(data, " ----- DATA IN CREATE ROADCOOPERATIVE ----- ")
			const jwt = await authToken.getToken()
			console.log(jwt)
			await setNewTokenId({ variables: { token: jwt, id: data.createRoadCooperativeForMember.roadCooperative.id } })
			await userProfileStorage({
				type: "setActiveRoadCooperative",
				payload: {
					roadCooperative: roadCooperative.RCName,
					role: "Puheenjohtaja",
					id: data.createRoadCooperativeForMember.roadCooperative.id,
					permission: "edit"
				}
			})
			setRoadCooperative(emptyRoadCooperative)
		}
	})

	const [createRoadCooperativeForExternalOperator] = useMutation(CREATE_ROAD_COOPERATIVE_FOR_EXTERNAL_OPERATOR, {
		onCompleted: async (data: any) => {
			console.log(data.createRoadCooperativeForExternalOperator, " ----- DATA IN CREATE ROADCOOPERATIVE ----- ")
			const jwt = await authToken.getToken()
			console.log(jwt)
			await setNewTokenIdExternalOperator({
				variables: { token: jwt, id: data.createRoadCooperativeForExternalOperator.roadCooperative.id }
			})
			await userProfileStorage({
				type: "setActiveRoadCooperative",
				payload: {
					roadCooperative: roadCooperative.RCName,
					role: "Puheenjohtaja",
					id: data.createRoadCooperativeForExternalOperator.roadCooperative.id,
					permission: "edit"
				}
			})
			setRoadCooperative(emptyRoadCooperative)
		}
	})

	const createNewRoadCooperative = () => {
		if (checkMemberParameters(roadCooperative, setAlert)) {
			console.log(roadCooperative.email)
			const roadCooperativeObj: any = {
				name: roadCooperative.RCName,
				businessId: roadCooperative.RCBusinessId,
				permissionUnitId: roadCooperative.RCPermissionUnit,
				municipality: roadCooperative.municipality
			}
			const roadCooperativeMemberObj = {
				name: roadCooperative.name,
				userEmail: user.email
			}
			const roadCooperativeUserBillingInformationObj = {
				email: user.email,
				billingAddress: roadCooperative.address,
				contactPerson: roadCooperative.contactPerson,
				countryCode: roadCooperative.countryCode,
				languageCode: roadCooperative.languageCode,
				meetingInviteType:
					typeof roadCooperative.meetingInviteType == "string" ? parseInt(roadCooperative.meetingInviteType) : roadCooperative.meetingInviteType,
				phoneNumber: roadCooperative.telNumber,
				postalDistrict: roadCooperative.postalDistrict,
				postcode: roadCooperative.postalCode,
				invoiceSendingType:
					typeof roadCooperative.invoiceSendingType == "string" ? parseInt(roadCooperative.invoiceSendingType) : roadCooperative.invoiceSendingType
			}

			roadCooperative.memberType === "yritys"
				? Object.assign(roadCooperativeUserBillingInformationObj, {
						businessId: roadCooperative.businessId,
						eInvoiceAddress: roadCooperative.eInvoiceAddress,
						eInvoiceOperator: roadCooperative.eInvoiceOperator
					})
				: console.log(roadCooperativeUserBillingInformationObj)

			if (roadCooperative.RCRole === "shareholder") {
				createRoadCooperativeForMember({
					variables: {
						roadCooperative: roadCooperativeObj,
						roadCooperativeMember: roadCooperativeMemberObj,
						roadCooperativeUserBillingInformation: roadCooperativeUserBillingInformationObj
					}
				})
			} else if (roadCooperative.RCRole === "expert") {
				createRoadCooperativeForExternalOperator({
					variables: {
						roadCooperative: roadCooperativeObj,
						roadCooperativeExternalOperator: roadCooperativeMemberObj,
						roadCooperativeExternalOperatorBillingInformation: roadCooperativeUserBillingInformationObj
					}
				})
			}
		}
	}

	useEffect(() => {
		getAllCountries().then((data) => {
			if (data) setAllCountries(data)
		})
		setPermission(rcStorage.permission)
	}, [])

	const handleBackClick = () => {
		if (step == "rcInfo") setCreateNewRC(false)
		else setStep("rcInfo")
	}

	const handleNextClick = () => {
		if (step == "rcInfo") {
			if (checkRCParameters(roadCooperative, setRCAlert)) setStep("personInfo")
		} else {
			if (checkMemberParameters(roadCooperative, setAlert)) createNewRoadCooperative()
		}
	}

	const handleChangeMemberType = (v: string | null) => {
		if (!v) {
			return
		} else {
			setRoadCooperative({ ...roadCooperative, memberType: v })
		}
	}

	const handleInfoClick = () => {
		const url = "https://storymaps.arcgis.com/stories/04cefb6993254e5ab4fdec5bc0f129cb"
		const link = document.createElement("a")
		link.href = url
		;(link.rel = "noreferrer"), (link.target = "_blank"), link.click()
	}

	return (
		<>
			<DialogCloseButton closeFunction={() => setCreateNewRC(false)} />
			<CustomDialogTitle>{step == "rcInfo" ? "Luo uusi tiekunta" : "Omat tiedot"}</CustomDialogTitle>
			<DialogContent
				sx={{
					justifyContent: "center",
					overflow: "hidden",
					overflowY: "auto",
					padding: "min(2vw, 20px)",
					textAlign: "center",
					display: "flex",
					width: step == "rcInfo" ? "min(calc(100vw - 64px), 630px)" : largeScreen ? "min(82vw, 630px)" : "min(calc(100vw - 64px), 950px)"
				}}
				elevation={10}
			>
				<Grid container sx={{ maxWidth: step == "rcInfo" ? "min(78vw, 500px)" : "min(78vw, 900px)" }} spacing={1}>
					{step == "rcInfo" ? (
						<>
							{permission === "edit" ? (
								<Grid xs={12} justifyContent="center">
									<LinkButton
										title="Pyydä tarjous tiekunnan perustamisesta"
										url="https://www.tievahti.fi/palvelut/tiekunnan-perustaminen/"
									></LinkButton>
								</Grid>
							) : null}
							<Grid item xs={12}>
								<TextField
									required
									value={roadCooperative.RCName}
									onChange={(e: { target: { value: string } }) => setRoadCooperative({ ...roadCooperative, RCName: e.target.value })}
									label="Tiekunnan nimi"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={roadCooperative.RCBusinessId}
									onChange={(e: { target: { value: string } }) => setRoadCooperative({ ...roadCooperative, RCBusinessId: e.target.value })}
									label="Y-tunnus"
									fullWidth
								/>
							</Grid>
							<Grid item container xs={12} alignItems="center" justifyContent="center">
								<Grid item xs={11}>
									<TextField
										value={roadCooperative.RCPermissionUnit}
										onChange={(e: { target: { value: string } }) =>
											setRoadCooperative({ ...roadCooperative, RCPermissionUnit: e.target.value })
										}
										label="Käyttöoikeusyksikkötunnus"
										fullWidth
									/>
								</Grid>
								<Grid item xs={1}>
									<Tooltip
										// sx={{ position: "absolute", marginTop: "12px", marginLeft: "500px" }}
										sx={{ marginLeft: "5px" }}
										title="Tiekunnan yksilöivä tunnus. Klikkaamalla pääset Metsäkeskuksen Tiekuntakarttaan, josta voit hakea oman tiekuntasi käyttöoikeusyksikkötunnuksen."
									>
										<IconButton color="info" onClick={handleInfoClick}>
											<InfoIcon />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth sx={{ alignItems: "center", marginLeft: "4px" }}>
									<TextField
										label="Roolini tiekunnassa *"
										value={roadCooperative.RCRole}
										select
										fullWidth
										variant="outlined"
										onChange={(e: { target: { value: string } }) => setRoadCooperative({ ...roadCooperative, RCRole: e.target.value })}
									>
										<MenuItem value={"shareholder"}>Osakas</MenuItem>
										<MenuItem value={"expert"}>Ulkopuolinen toimija</MenuItem>
									</TextField>
								</FormControl>
							</Grid>
							{/* tämä kuntoon */}
							<Grid item xs={12}>
								<MunicipalitySelect
									initialValue={roadCooperative.municipality}
									onMunicipalityChange={(value: any) => setRoadCooperative({ ...roadCooperative, municipality: value })}
								/>
							</Grid>
							{RCalert ? (
								<Grid item xs={12} sx={{ height: "80px", marginTop: "10px" }}>
									<Alert sx={{ wordWrap: "break-word" }} severity="error">
										{RCalert}
									</Alert>
								</Grid>
							) : (
								<Grid item xs={12} sx={{ height: "80px", marginTop: "10px" }} />
							)}
						</>
					) : (
						<Grid item container xs={12} columnSpacing={4} rowSpacing={1}>
							<Grid item xs={12}>
								<ToggleButtonGroup
									value={roadCooperative.memberType}
									color="primary"
									onChange={(e: ReactElement, v: string | null) => handleChangeMemberType(v)}
									exclusive
									size="large"
									aria-label="recipient type"
								>
									<ToggleButton value="yritys" fullWidth sx={{ alignItems: "space-between", maxWidth: 300 }}>
										Yritys
										<BusinessIcon sx={{ marginLeft: 2, fontSize: 32 }} />
									</ToggleButton>
									<ToggleButton value="yksityishenkilö" fullWidth sx={{ alignItems: "space-between", maxWidth: 300 }}>
										<PersonIcon sx={{ marginRight: 2, fontSize: 32 }} />
										Henkilö
									</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
							<Grid item xs={12} md={6} xl={12}>
								<Grid item xs={12}>
									{roadCooperative.memberType === "yritys" ? (
										<OrganisationUserInfo
											roadCooperative={roadCooperative}
											setRoadCooperative={setRoadCooperative}
											allCountries={allCountries}
										/>
									) : (
										<PersonUserInfo roadCooperative={roadCooperative} setRoadCooperative={setRoadCooperative} allCountries={allCountries} />
									)}
								</Grid>
							</Grid>
							<Grid item xs={12} md={6} xl={12} sx={{ alignSelf: "center" }}>
								<Grid item xs={12}>
									<FormLabel>Laskun toimitustapa *</FormLabel>
									<RadioGroup
										row
										onChange={(e: any) => setRoadCooperative({ ...roadCooperative, invoiceSendingType: e.target.value })}
										name="row-radio-buttons-group"
										sx={{ justifyContent: "center" }}
									>
										<FormControlLabel value={1} control={<Radio />} label={<Typography sx={{ fontWeight: "500" }}>Kirje</Typography>} />
										<FormControlLabel
											value={2}
											control={<Radio />}
											label={<Typography sx={{ fontWeight: "500" }}>Sähköposti</Typography>}
										/>
										<FormControlLabel
											value={3}
											control={<Radio />}
											disabled={roadCooperative.memberType === "yksityishenkilö"}
											label={<Typography sx={{ fontWeight: "500" }}>Verkkolasku</Typography>}
										/>
									</RadioGroup>
									{roadCooperative.invoiceSendingType == 3 && roadCooperative.memberType === "yritys" ? (
										<Stack
											direction="row"
											alignItems="center"
											alignContent="space-between"
											justifyContent="center"
											sx={{ width: "100%", marginBottom: "10px" }}
										>
											<TextField
												label="Verkkolaskuosoite"
												variant="outlined"
												fullWidth
												onChange={(e: any) => setRoadCooperative({ ...roadCooperative, eInvoiceAddress: e.target.value })}
												value={roadCooperative.eInvoiceAddress}
												required
												sx={{ marginRight: "8px" }}
											/>
											<TextField
												label="Operaattoritunnus"
												variant="outlined"
												fullWidth
												onChange={(e: any) => setRoadCooperative({ ...roadCooperative, eInvoiceOperator: e.target.value })}
												value={roadCooperative.eInvoiceOperator}
												required
												sx={{ marginLeft: "8px" }}
											/>
										</Stack>
									) : null}
								</Grid>
								<Grid item xs={12}>
									<FormLabel>Kokouskutsun toimitustapa *</FormLabel>
									<RadioGroup
										row
										name="row-radio-buttons-group"
										onChange={(e: any) => setRoadCooperative({ ...roadCooperative, meetingInviteType: e.target.value })}
										sx={{ justifyContent: "center" }}
									>
										<FormControlLabel value={1} control={<Radio />} label={<Typography sx={{ fontWeight: "500" }}>Kirje</Typography>} />
										<FormControlLabel
											value={2}
											control={<Radio />}
											label={<Typography sx={{ fontWeight: "500" }}>Sähköposti</Typography>}
										/>
									</RadioGroup>{" "}
									<br />
								</Grid>
								<Grid item xs={12} sx={{ justifyContent: "center", display: "flex", height: "88px" }}>
									{alert ? (
										<Alert sx={{ wordWrap: "break-word" }} severity="error">
											{alert}
										</Alert>
									) : null}
								</Grid>
							</Grid>
							{/* <Grid item container xs={12} spacing={1} sx={{ paddingBottom: xsScreen ? "40px" : 0 }}>
								<Grid item xs={6} sx={{ textAlign: "left" }}>
									<Button fullWidth variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => setStep("rcInfo")}>
										Takaisin
									</Button>
								</Grid>
								<Grid item xs={6} sx={{ textAlign: "right" }}>
									<Button fullWidth variant="contained" endIcon={<CheckIcon />} onClick={handleCreateRoadCooperative}>
										Luo tiekunta
									</Button>
								</Grid>
							</Grid> */}
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<CustomDialogActions>
				<Button
					variant="outlined"
					fullWidth
					sx={{ marginRight: "10px" }}
					startIcon={step == "rcInfo" ? undefined : <ArrowBackIcon />}
					onClick={handleBackClick}
				>
					{step == "rcInfo" ? "Sulje" : "Takaisin"}
				</Button>
				<Button
					variant="contained"
					fullWidth
					sx={{ marginLeft: "10px" }}
					endIcon={step == "rcInfo" ? <ArrowForwardIcon /> : <CheckIcon />}
					onClick={handleNextClick}
				>
					{step == "rcInfo" ? "Seuraava" : "Luo tiekunta"}
				</Button>
			</CustomDialogActions>
		</>
	)
}

export default CreateRCDialog
