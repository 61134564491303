import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import EditIcon from "@mui/icons-material/Edit"
import {
	Box,
	Button,
	Collapse,
	Dialog,
	DialogContent,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import EditEstablishmentDialog from "../../HomePage/HomePageDrawer/EditEstablishmentDialog"
import CustomDialogActions from "../../reusables/CustomDialogActions"
import CustomDialogTitle from "../../reusables/CustomDialogTitle"
import CustomNotification from "../../reusables/CustomNotification"
import DialogCloseButton from "../../reusables/DialogCloseButton"
import { useMapState } from "../../../utils/providers/mapProvider/mapProvider"

import AddIcon from "@mui/icons-material/Add"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"

import { Tooltip } from "@mui/material"
import { FormControl } from "@mui/material"
import { Select } from "@mui/material"
import { InputLabel } from "@mui/material"
import MonetaryInput from "../../../utils/MonetaryInput"
import { Paper } from "@mui/material"
import { TableContainer } from "@mui/material"
import { Checkbox } from "@mui/material"
import userProfileStorage from "../../../utils/providers/userProfileProvider/userProfileProvider"
import { MutationDeleteChip, MutationIconButton } from "../../reusables/MutationButtonChipFab"
import config from "../../../utils/config"
import { ESTABLISHMENT_MUTATIONS } from "../../HomePage/HomePageDrawer/CreateEstablishmentDialog"

const GET_COST_CENTERS = gql`
	query GetCostCenters {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					name
					id
				}
			}
		}
	}
`

export const PROPERTY_MUTATION = gql`
	mutation CreateProperty(
		$id: Int
		$unitPropertyId: IdType
		$mmlPropertyId: String = ""
		$plotId: Int = 10
		$propertyName: String = ""
		$unitizationCostCenterId: IdType = ""
		$unitizationId: IdType = ""
		$coordinates: JSON = ""
		$propertyTag: Int
		$costCenterId: Int
		$type: String
		$from: String
	) {
		createUnitizationPropertyExtended(
			input: {
				unitizationProperty: {
					id: $id
					unitPropertyId: $unitPropertyId
					mmlPropertyId: $mmlPropertyId
					plotId: $plotId
					propertyName: $propertyName
					unitizationCostCenterId: $unitizationCostCenterId
					unitizationId: $unitizationId
					coordinates: $coordinates
					propertyTag: $propertyTag
					costCenterId: $costCenterId
					type: $type
					from: $from
				}
			}
		) {
			unitizationProperty {
				id
			}
		}
	}
`

const CREATE_PROPERTY = gql`
	mutation CreateProperty($costCenterId: IdType = "", $mmlPropertyId: String = "", $plotId: Int = 10, $propertyName: String = "") {
		createProperty(input: { property: { costCenterId: $costCenterId, mmlPropertyId: $mmlPropertyId, plotId: $plotId, propertyName: $propertyName } }) {
			clientMutationId
			property {
				id
			}
		}
	}
`

const CREATE_UNITIZATION_PROPERTY = gql`
	mutation CreateUnitizationProperty($mmlPropertyId: String = "", $plotId: Int = 10, $propertyName: String = "", $unitizationCostCenterId: IdType = "") {
		createUnitizationProperty(
			input: {
				unitizationProperty: {
					mmlPropertyId: $mmlPropertyId
					plotId: $plotId
					propertyName: $propertyName
					unitizationCostCenterId: $unitizationCostCenterId
				}
			}
		) {
			clientMutationId
			unitizationProperty {
				id
			}
		}
	}
`

const CREATE_ESTABLISHMENT = gql`
	mutation CreateEstablishment(
		$additionalInformation: String = ""
		$amount: Float
		$costCenterId: IdType = ""
		$discretionalyCorrectionMultiplier: Float
		$distanceCorrectionMultiplier: Float
		$operatingDistance: Float
		$propertyId: IdType = ""
		$roadCooperativeMemberId: IdType = ""
		$roadUsageUnitId: IdType = ""
		$weightCorrectionMultiplier: Float
	) {
		createEstablishment(
			input: {
				establishment: {
					additionalInformation: $additionalInformation
					amount: $amount
					costCenterId: $costCenterId
					discretionalyCorrectionMultiplier: $discretionalyCorrectionMultiplier
					distanceCorrectionMultiplier: $distanceCorrectionMultiplier
					lateralDirection: 1
					lateralDirectionCorrectionMultiplier: 1
					operatingDistance: $operatingDistance
					propertyId: $propertyId
					roadCooperativeMemberId: $roadCooperativeMemberId
					roadUsageUnitId: $roadUsageUnitId
					weightCorrectionMultiplier: $weightCorrectionMultiplier
				}
			}
		) {
			establishment {
				id
			}
		}
	}
`

const DELETE_PROPERTY = gql`
	mutation DeleteProperty($id: Int = 10) {
		deletePropertyById(input: { id: $id }) {
			clientMutationId
		}
	}
`

const GET_UNITS_PRESENTATION_TYPE_AND_COST_CENTERS = gql`
	query GetUnitsPresentationType {
		roadCooperativeWithJWT {
			id
			roadCooperativeSettingsByRoadCooperativeId {
				nodes {
					id
					unitsPresentationType
				}
			}
			costCentersByRoadCooperativeId {
				totalCount
				nodes {
					id
					name
					idColor
				}
			}
		}
	}
`

const GET_ROAD_USAGE_UNITS_AND_MEMBERS = gql`
	query GetRoadUsageUnitsAndMembers {
		roadCooperativeWithJWT {
			roadCooperativeMembersByRoadCooperativeId {
				nodes {
					name
					id
				}
			}
			roadUsageUnitsByRoadCooperativeId {
				nodes {
					id
					weight
					roadUsageUnit
					roadUsageUnitType
				}
			}
		}
	}
`

const GET_COST_CENTER_PROPERTIES = gql`
	query GetCostCenterProperties($id: Int = 10) {
		costCenterById(id: $id) {
			propertiesByCostCenterId {
				nodes {
					id
					plotId
					propertyName
				}
			}
		}
	}
`

const CREATE_ROAD_USAGEUNIT = gql`
	mutation CreateRoadUsageUnit($roadCooperativeId: UUID!, $roadUsageUnit: String = "", $roadUsageUnitType: String = "") {
		createRoadUsageUnit(
			input: { roadUsageUnit: { roadCooperativeId: $roadCooperativeId, roadUsageUnit: $roadUsageUnit, roadUsageUnitType: $roadUsageUnitType, weight: 1 } }
		) {
			roadUsageUnit {
				id
			}
		}
	}
`

const MapPropertyInfoPopup = () => {
	// console.log(propertyData)
	const { state, dispatch } = useMapState()

	return (
		<>
			<Box sx={{ width: "100%", height: "100%" }}>{state.propertyDialogData?.addProperty ? <AddProperty /> : <EditProperty />}</Box>
		</>
	)
}

const EditProperty = () => {
	const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
	const [addToCostCenter, setAddToCostCenter] = useState(false)
	const [deleteProperty] = useMutation(PROPERTY_MUTATION)
	const [unitsViewType, setUnitsViewType] = useState<UnitsViewType>("easy")
	const [costCenterCount, setCostCenterCount] = useState(0)
	const [allCostCenters, setAllCostCenters] = useState<CostCenter[]>([])
	const [selectedCostCenter, setSelectedCostCenter] = useState<number>()
	const [propertyName, setPropertyName] = useState("")
	const [deletePropertiesIds, setDeletePropertiesIds] = useState<number[]>([])

	const { state, dispatch } = useMapState()

	const { data } = useQuery(GET_UNITS_PRESENTATION_TYPE_AND_COST_CENTERS, {
		onCompleted(data) {
			setUnitsViewType(data.roadCooperativeWithJWT.roadCooperativeSettingsByRoadCooperativeId.nodes[0].unitsPresentationType)
			setCostCenterCount(data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.totalCount)
			setAllCostCenters(data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes)
		}
	})

	const [getCostCenterProperties] = useLazyQuery(GET_COST_CENTER_PROPERTIES)

	const [createProperty] = useMutation(CREATE_PROPERTY)
	const [createUnitizationProperty] = useMutation(CREATE_UNITIZATION_PROPERTY)

	const handleDeleteProperty = async (deletingId: number = state.propertyDialogData?.data ? state.propertyDialogData?.data[0]?.id : -1) => {
		console.log(deletingId)
		try {
			for (let i = 0, len = deletePropertiesIds.length; len > i; i++) {
				const propertyId = deletePropertiesIds[i]
				console.log(propertyId)
				await deleteProperty({
					variables: { id: propertyId, type: "delete", from: "homePage" },
					onError(error, clientOptions) {
						null
					}
				})
			}
			dispatch({ type: "setPropertyDialogData", payload: { dataChanged: true } })
			const el: any = document.getElementsByClassName("map-property-info-popup")
			if (el) el[0].style.display = "none"
		} catch (error) {
			null
		}
	}

	const handleSaveAddToCostCenter = async () => {
		try {
			if (!state.propertyDialogData?.data) return
			const property = state.propertyDialogData?.data[0]
			const { data: allCCProperties } = await getCostCenterProperties({ variables: { id: selectedCostCenter } })

			// käsittelijä joka estää luomasta samaa kiinteistöä samalle kustannuspaikalle
			for (let i = 0, len = allCCProperties.costCenterById.propertiesByCostCenterId.nodes.length; len > i; i++) {
				const property2 = allCCProperties.costCenterById.propertiesByCostCenterId.nodes[i]
				console.log(allCCProperties.costCenterById.propertiesByCostCenterId.nodes[i])
				if (property2.plotId == property.plotId) {
					toast.error("Kiinteistö kuuluu jo kyseiseen tienosaan")
					return
				}
			}

			// Luodaan kiinteistö
			createProperty({
				variables: { costCenterId: selectedCostCenter, mmlPropertyId: property.mmlPropertyId, plotId: property.plotId, propertyName: propertyName }
			})

			// createUnitizationProperty({
			// 	variables: {
			// 		unitizationCostCenterId: selectedCostCenter,
			// 		mmlPropertyId: property.mmlPropertyId,
			// 		plotId: property.plotId,
			// 		propertyName: propertyName,
			// 	},
			// })

			// Piilotetaan popuppi ja ladataan uusi data kartalle
			dispatch({ type: "setPropertyDialogData", payload: { dataChanged: true } })
			const el: any = document.getElementsByClassName("map-property-info-popup")
			if (el) el[0].style.display = "none"
		} catch (error) {
			console.log(error)
		}
	}

	const handleOpenDelete = () => {
		if (!state.propertyDialogData?.data) return
		if (state.propertyDialogData?.data.length == 1) {
			setDeletePropertiesIds([state.propertyDialogData?.data[0].id])
		} else {
			setDeletePropertiesIds([])
		}
		setOpenConfirmDelete(true)
	}

	const handleCloseDelete = () => {
		setDeletePropertiesIds([])
		setOpenConfirmDelete(false)
	}

	const handleToggleAddPropertyInDeleteList = (propertyId: number) => {
		const tempPropertyIdList = []
		let propertyIsDeletedFromList = false
		for (let i = 0, len = deletePropertiesIds.length; len > i; i++) {
			if (deletePropertiesIds[i] != propertyId) {
				tempPropertyIdList.push(deletePropertiesIds[i])
			} else {
				propertyIsDeletedFromList = true
			}
		}
		if (!propertyIsDeletedFromList) {
			tempPropertyIdList.push(propertyId)
		}
		setDeletePropertiesIds(tempPropertyIdList)
	}

	return (
		<>
			<Dialog open={addToCostCenter}>
				<DialogCloseButton closeFunction={setAddToCostCenter}></DialogCloseButton>
				<CustomDialogTitle>Lisää tienosaan</CustomDialogTitle>
				<DialogContent sx={{ textAlign: "center" }}>
					<Stack>
						<TextField
							label="Tie"
							select
							fullWidth={true}
							sx={{
								maxWidth: "218px"
							}}
							value={selectedCostCenter}
							onChange={(e: { target: { value: number } }) => setSelectedCostCenter(e.target.value)}
						>
							{allCostCenters.map((cc: CostCenter) => {
								return (
									<MenuItem key={cc.id} value={cc.id}>
										{cc.name}
									</MenuItem>
								)
							})}
						</TextField>
						<TextField
							disabled={selectedCostCenter == undefined ? true : false}
							label="Tilan nimi"
							value={propertyName}
							onChange={(e: { target: { value: string } }) => setPropertyName(e.target.value)}
						></TextField>
					</Stack>
				</DialogContent>
				<CustomDialogActions>
					<Button variant={"outlined"} onClick={() => setAddToCostCenter(false)}>
						Sulje
					</Button>
					<Button variant={"contained"} onClick={handleSaveAddToCostCenter}>
						Tallenna
					</Button>
				</CustomDialogActions>
			</Dialog>
			<Dialog open={openConfirmDelete}>
				<DialogCloseButton closeFunction={handleCloseDelete} />
				<CustomDialogTitle>Poista kiinteistö</CustomDialogTitle>
				<DialogContent sx={{ paddingX: "40px", paddingTop: "15px", paddingBottom: "30px", textAlign: "center" }}>
					<Typography sx={{}}>Haluatko varmasti poistaa kiinteistön? </Typography>
					<Typography sx={{}}>Poistat myös samalla kiinteistöön liitetyt yksiköt.</Typography>

					{state.propertyDialogData?.data?.length == 1 ? null : (
						<>
							<Divider
								sx={{
									marginTop: "5px",
									marginBottom: "5px"
								}}
							/>
							<CustomNotification severity="info">
								Kyseinen kiinteistö kuuluu useaan tienosaan. Valitse mistä tienosasta kiinteistö poistetaan.
							</CustomNotification>
							<Stack
								sx={{
									width: "100%",
									justifyContent: "space-between"
								}}
								direction="column"
							>
								{state.propertyDialogData?.data == undefined
									? null
									: state.propertyDialogData?.data.map((property: any, index: number) => {
											return (
												<React.Fragment key={property.id}>
													<Box
														variant="outlined"
														sx={{
															display: "flex",

															flexDirection: "row",
															alignItems: "center",
															gap: 1.5,
															// p: 2,
															minWidth: 120
														}}
													>
														<Checkbox
															id={property.costCenterByCostCenterId.id}
															value={property.costCenterByCostCenterId.name}
															onClick={() => handleToggleAddPropertyInDeleteList(property.id)}
															checkedIcon={<CheckCircleRoundedIcon />}
															icon={<RadioButtonUncheckedIcon />}
														/>
														<Typography>{property.costCenterByCostCenterId.name}</Typography>
													</Box>
												</React.Fragment>
											)
										})}
							</Stack>
						</>
					)}
				</DialogContent>
				<CustomDialogActions>
					<Button variant={"outlined"} color={"cancel"} onClick={() => handleCloseDelete()}>
						Peruuta
					</Button>
					<Button variant="contained" color="close" onClick={() => handleDeleteProperty()}>
						Poista
					</Button>
				</CustomDialogActions>
			</Dialog>
			<MutationDeleteChip deleteFunction={() => handleOpenDelete()}></MutationDeleteChip>

			{state.propertyDialogData?.data == undefined ? null : (
				<>
					{state.propertyDialogData.data?.length > 1 && (
						<Typography sx={{ fontWeight: "500", fontStyle: "italic", fontSize: "0.8rem", position: "absolute", transform: "translateY(-15px)" }}>
							Kiinteistön yksiköt listattuna tienosittain
						</Typography>
					)}
					{state.propertyDialogData.data.map((cc: any, index: number) => {
						return (
							<React.Fragment key={cc.costCenterByCostCenterId.name}>
								<CostCenterRow property={cc} unitsViewType={unitsViewType} index={index} />
							</React.Fragment>
						)
					})}
				</>
			)}
			{costCenterCount > 1 && (
				<Tooltip title="Lisää kiinteistö toiseen tienosaan">
					<Button onClick={() => setAddToCostCenter(true)} variant="outlined" sx={{ position: "absolute", bottom: "30px" }}>
						Lisää kiinteistö tienosaan <AddIcon sx={{ marginLeft: "10px" }} />
					</Button>
				</Tooltip>
			)}
		</>
	)
}

type CostCenterRowType = {
	property: Property
	unitsViewType: UnitsViewType
	index: number
}

const CostCenterRow = ({ property, unitsViewType, index }: CostCenterRowType) => {
	const [openAddUnit, setOpenAddUnit] = useState(false)
	const [propertyEstablishments, setPropertyEstablishments] = useState<any>(property.establishmentsByPropertyId.nodes)
	const { state, dispatch } = useMapState()

	return (
		<>
			<CreateEstablishmentDialog open={openAddUnit} setOpen={setOpenAddUnit} property={property} unitsViewType={unitsViewType} />
			{state.propertyDialogData?.data == undefined ? null : state.propertyDialogData?.data?.length > 1 ? (
				<Typography sx={{ fontSize: "1.0rem" }}>{property.costCenterByCostCenterId.name} Yksiköt</Typography>
			) : null}
			<Table sx={{ marginBottom: "20px" }} padding={"none"}>
				<TableHead>
					<TableRow sx={{ marginLeft: "5px" }}>
						{unitsViewType == "professional" ? (
							<>
								<TableCell sx={{ padding: "0px 10px 0px 10px" }}></TableCell>
								<TableCell sx={{ padding: "0px 10px 0px 10px" }} align="center">
									Liikennelaji
								</TableCell>
							</>
						) : null}
						<TableCell sx={{ padding: "0px 10px 0px 10px" }} align="center">
							Osakas
						</TableCell>
						<TableCell align="center" sx={{ padding: "0px 10px 0px 10px" }}>
							Tieyksiköt
						</TableCell>
						<TableCell sx={{ padding: "0px 10px 0px 10px" }}>
							<Tooltip title={"Lisää yksiköitä"}>
								<MutationIconButton color="primary" onClick={() => setOpenAddUnit(true)}>
									<AddIcon />
								</MutationIconButton>
							</Tooltip>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{propertyEstablishments.length > 0 ? (
						propertyEstablishments.map((est: any) => {
							return <PropertyEstablishmentRow establishment={est} key={est.id} unitsViewType={unitsViewType} />
						})
					) : (
						<TableRow>
							<TableCell sx={{ height: "40px" }} colspan={5}>
								<Typography sx={{ width: "100%", textAlign: "center", alignText: "center", fontWeight: "400" }}>Ei yksiköitä</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</>
	)
}

interface EstablishmentData {
	additionalInformation: string
	amount: number
	costCenterId: number
	discretionalyCorrectionMultiplier: number
	distanceCorrectionMultiplier: number
	operatingDistance: number
	propertyId: number
	roadCooperativeMemberId?: string
	roadUsageUnitId?: number
	weightCorrectionMultiplier: number
}

type CreateEstablishmentDialogType = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	property: Property
	unitsViewType: UnitsViewType
}

const CreateEstablishmentDialog = ({ open, setOpen, property, unitsViewType }: CreateEstablishmentDialogType) => {
	const { state, dispatch } = useMapState()

	const costCenterId = property.costCenterByCostCenterId.id ?? -1
	const [selectedRoadUsageUnit, setSelectedRoadUsageUnit] = useState<RoadUsageUnit>()
	const [allMembers, setAllMembers] = useState<RoadCooperativeMemberType[]>([])
	const [allRoadUsageUnits, setAllRoadUsageUnits] = useState<RoadUsageUnit[]>([])
	const [roadUnits, setRoadUnits] = useState(0)
	const [establishmentData, setEstablishmentData] = useState<EstablishmentData>({
		additionalInformation: "",
		amount: 1,
		discretionalyCorrectionMultiplier: 1,
		distanceCorrectionMultiplier: 1,
		propertyId: property.id,
		operatingDistance: 1,
		weightCorrectionMultiplier: 1,
		costCenterId: costCenterId
	})

	const { data, loading } = useQuery(GET_ROAD_USAGE_UNITS_AND_MEMBERS, {
		onCompleted(data) {
			const func = async () => {
				const members = data.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.nodes
				const roadUsageUnits = data.roadCooperativeWithJWT.roadUsageUnitsByRoadCooperativeId.nodes
				setAllMembers(members)
				setAllRoadUsageUnits(roadUsageUnits)
				if (unitsViewType == "easy") {
					let defaultRoadUsageUnitId = null
					for (let i = 0, len = roadUsageUnits.length; len > i; i++) {
						if (roadUsageUnits[i].roadUsageUnitType == "oletus") {
							defaultRoadUsageUnitId = roadUsageUnits[i].id
						}
					}
					if (defaultRoadUsageUnitId == null) {
						const RC = userProfileStorage({ type: "getActiveRoadCooperative" })
						await createRoadUsageUnit({
							variables: { roadCooperativeId: RC.id, roadUsageUnit: "o", roadUsageUnitType: "oletus" },
							onCompleted(data, clientOptions) {
								const usageUnitId = data.createRoadUsageUnit.roadUsageUnit.id
								defaultRoadUsageUnitId = usageUnitId
							}
						})
					}
					setEstablishmentData({ ...establishmentData, roadUsageUnitId: defaultRoadUsageUnitId })
				}
			}
			func()
		}
	})
	const [createEstablishment] = useMutation(ESTABLISHMENT_MUTATIONS)
	const [createRoadUsageUnit] = useMutation(CREATE_ROAD_USAGEUNIT)

	useEffect(() => {
		const tempRoadUnits =
			establishmentData.amount *
			establishmentData.operatingDistance *
			establishmentData.discretionalyCorrectionMultiplier *
			establishmentData.distanceCorrectionMultiplier *
			establishmentData.weightCorrectionMultiplier *
			(selectedRoadUsageUnit?.weight ?? 0)
		setRoadUnits(tempRoadUnits)
	}, [establishmentData])

	const handleCreateEstablishment = async () => {
		if (!establishmentData.roadCooperativeMemberId) {
			toast.error("Osakas tieto puuttuu", { toastId: "createEstablsihmentMemberWarging" })
			return
		}
		if (!establishmentData.roadUsageUnitId) {
			toast.error("Liikennelaji tieto puuttuu", { toastId: "createEstablsihmentRoadUsageUnitWarging" })
			return
		}

		await createEstablishment({ variables: { ...establishmentData, type: "create", from: "homePage" } })

		dispatch({ type: "setPropertyDialogData", payload: { dataChanged: true } })
		setOpen(false)
	}

	const handleSelectRoadUsageUnit = (usageUnit: RoadUsageUnit) => {
		setSelectedRoadUsageUnit(usageUnit)
	}

	return (
		<>
			<Dialog open={open}>
				<DialogCloseButton closeFunction={setOpen} functionParams={false} />
				<CustomDialogTitle>Lisää yksikkö</CustomDialogTitle>
				<DialogContent sx={{ textAlign: "center", padding: "20px 10px 20px 10px" }}>
					{unitsViewType == "professional" ? (
						<>
							<TextField
								disabled
								inputProps={{ sx: { zIndex: "1308" } }}
								sx={{ borderRadius: "10px" }}
								value={property.mmlPropertyId}
								label="Kiinteistötunnus"
								variant="outlined"
							>
								{property.mmlPropertyId}
								{property.propertyTag ? <>/{property.propertyTag}</> : null}
							</TextField>
							<TextField
								inputProps={{
									maxLength: 100,
									sx: { zIndex: "1308" }
								}}
								fullWidth
								SelectDisplayProps={{ sx: { zIndex: "1308" } }}
								MenuProps={{ sx: { zIndex: "1308" } }}
								SelectProps={{ sx: { zIndex: 1308 } }}
								variant="outlined"
								sx={{ borderRadius: "10px", maxWidth: "218px" }}
								id="select-usage-unit-type"
								select
								label="Liikennelaji"
								value={establishmentData.roadUsageUnitId ?? ""}
								onChange={(e: { target: { value: number } }) => setEstablishmentData({ ...establishmentData, roadUsageUnitId: e.target.value })}
							>
								{loading ? (
									<MenuItem value={0}>Ladataan...</MenuItem>
								) : (
									allRoadUsageUnits.map((t: RoadUsageUnit) => {
										return (
											<MenuItem key={t} value={t.id} onClick={() => handleSelectRoadUsageUnit(t)}>
												{t.roadUsageUnitType} {t.roadUsageUnit} {t.weight}
											</MenuItem>
										)
									})
								)}
							</TextField>

							<TextField
								disabled
								value={selectedRoadUsageUnit?.weight ?? 0}
								sx={{ width: "218px" }}
								label="Painoluku (t)"
								variant="outlined"
							></TextField>

							<MonetaryInput
								maximumDecimals={3}
								valueSetter={(v: number) => setEstablishmentData({ ...establishmentData, weightCorrectionMultiplier: v })}
								value={establishmentData.weightCorrectionMultiplier}
								baseProps={{ sx: { width: "218px" } }}
								label="Painoluvun korj.ker."
								variant="outlined"
							/>

							<MonetaryInput
								maximumDecimals={3}
								valueSetter={(v: number) => setEstablishmentData({ ...establishmentData, operatingDistance: v })}
								value={establishmentData.operatingDistance}
								baseProps={{ sx: { width: "218px" } }}
								label="Käyttömatka (km)"
								variant="outlined"
							/>
							<MonetaryInput
								maximumDecimals={3}
								valueSetter={(v: number) => setEstablishmentData({ ...establishmentData, distanceCorrectionMultiplier: v })}
								value={establishmentData.distanceCorrectionMultiplier}
								baseProps={{ sx: { width: "218px" } }}
								label="Matkan korj.ker."
								variant="outlined"
							/>
							<MonetaryInput
								maximumDecimals={3}
								valueSetter={(v: number) => setEstablishmentData({ ...establishmentData, amount: v })}
								value={establishmentData.amount ?? 0}
								baseProps={{ sx: { width: "218px" } }}
								label="Lukumäärä (ha/kpl)"
								variant="outlined"
							/>
							<MonetaryInput
								maximumDecimals={3}
								valueSetter={(v: number) => setEstablishmentData({ ...establishmentData, discretionalyCorrectionMultiplier: v })}
								value={establishmentData.discretionalyCorrectionMultiplier}
								baseProps={{ sx: { width: "218px" } }}
								label="Harkinnanvarainen korj.ker."
								variant="outlined"
							/>
							<TextField
								inputProps={{
									maxLength: 500
								}}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setEstablishmentData({ ...establishmentData, additionalInformation: e.target.value })
								}
								value={establishmentData.additionalInformation}
								multiline
								maxRows={4}
								label="Lisätietoa"
								variant="outlined"
								sx={{ width: "218px", paddingBottom: "10px" }}
								fullWidth
							></TextField>
							<TextField disabled value={roadUnits} label="Tieyksiköt" sx={{ width: "218px" }} variant="outlined"></TextField>
							<Divider sx={{ width: "80%", marginLeft: "10%" }}></Divider>
							<FormControl sx={{ marginTop: "10px", width: "80%" }}>
								<InputLabel sx={{ marginTop: "10px" }}>Osakas</InputLabel>
								<Select
									select
									value={establishmentData.roadCooperativeMemberId ?? ""}
									label={<Typography sx={{ fontWeight: "500" }}>Osakas</Typography>}
									fullWidth
									sx={{ maxWidth: "100%", margin: 0, marginTop: "10px", zIndex: "1308", borderRadius: "10px" }}
									inputProps={{ sx: { zIndex: "1308" } }}
									SelectDisplayProps={{ sx: { zIndex: "1308" } }}
									MenuProps={{ sx: { zIndex: "1308" } }}
									SelectProps={{ sx: { zIndex: 1308 } }}
									onChange={(e: { target: { value: string } }) =>
										setEstablishmentData({ ...establishmentData, roadCooperativeMemberId: e.target.value })
									}
								>
									{!allMembers ? (
										<MenuItem value={0}>ladataan...</MenuItem>
									) : (
										allMembers.map((member: RoadCooperativeMemberType, index: number) => {
											return (
												<MenuItem value={member.id} key={index}>
													{member.name}
												</MenuItem>
											)
										})
									)}
								</Select>
							</FormControl>
						</>
					) : (
						<>
							<FormControl fullWidth sx={{ marginTop: "10px", marginBottom: "5px", minWidth: "218px", maxWidth: "446px" }}>
								<InputLabel>Kiinteistötunnus</InputLabel>
								<Select
									disabled
									inputProps={{ sx: { zIndex: "1308" } }}
									SelectDisplayProps={{ sx: { zIndex: "1308" } }}
									MenuProps={{ sx: { zIndex: "1308" } }}
									SelectProps={{ sx: { zIndex: 1308 } }}
									select
									sx={{ borderRadius: "10px" }}
									value={1}
									label="Kiinteistötunnus"
									variant="outlined"
								>
									<MenuItem value={1}>
										{property.mmlPropertyId}
										{property.propertyTag ? <>/{property.propertyTag}</> : null}
									</MenuItem>
								</Select>
							</FormControl>
							<MonetaryInput
								maximumDecimals={3}
								valueSetter={(v: number) => setEstablishmentData({ ...establishmentData, amount: v })}
								value={establishmentData.amount ?? 0}
								baseProps={{ sx: { width: "218px" } }}
								label="Tieyksiköt"
								variant="outlined"
							/>
							<TextField
								inputProps={{
									maxLength: 500
								}}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setEstablishmentData({ ...establishmentData, additionalInformation: e.target.value })
								}
								value={establishmentData.additionalInformation}
								multiline
								maxRows={4}
								label="Lisätietoa"
								variant="outlined"
								sx={{ width: "218px", paddingBottom: "10px" }}
								fullWidth
							></TextField>
							<Divider sx={{ width: "80%", marginLeft: "10%" }}></Divider>
							<FormControl sx={{ marginTop: "10px", width: "80%" }}>
								<InputLabel sx={{ marginTop: "10px" }}>Osakas</InputLabel>
								<Select
									select
									value={establishmentData.roadCooperativeMemberId ?? ""}
									label={<Typography sx={{ fontWeight: "500" }}>Osakas</Typography>}
									fullWidth
									sx={{ maxWidth: "100%", margin: 0, marginTop: "10px", zIndex: "1308", borderRadius: "10px" }}
									inputProps={{ sx: { zIndex: "1308" } }}
									SelectDisplayProps={{ sx: { zIndex: "1308" } }}
									MenuProps={{ sx: { zIndex: "1308" } }}
									SelectProps={{ sx: { zIndex: 1308 } }}
									onChange={(e: { target: { value: string } }) =>
										setEstablishmentData({ ...establishmentData, roadCooperativeMemberId: e.target.value })
									}
								>
									{!allMembers ? (
										<MenuItem value={0}>ladataan...</MenuItem>
									) : (
										allMembers.map((user: RoadCooperativeMemberType, index: number) => {
											return (
												<MenuItem value={user.id} key={index}>
													{user.name}
												</MenuItem>
											)
										})
									)}
								</Select>
							</FormControl>
						</>
					)}
				</DialogContent>
				<CustomDialogActions>
					<Button variant={"contained"} onClick={() => setOpen(false)}>
						Sulje
					</Button>
					<Button variant={"contained"} onClick={() => handleCreateEstablishment()}>
						Tallenna
					</Button>
				</CustomDialogActions>
			</Dialog>
		</>
	)
}

const PropertyEstablishmentRow = ({
	establishment,
	refetch,
	refetchMapPropertiesData,
	unitsViewType
}: {
	refetchMapPropertiesData?: () => void
	establishment: Establishment
	refetch?: () => void
	unitsViewType: UnitsViewType | undefined
}) => {
	const [open, setOpen] = useState(false)
	const [openEdit, setOpenEdit] = useState(false)

	const { state, dispatch } = useMapState()

	const handleMultiDataRefetch = () => {
		if (refetchMapPropertiesData) refetchMapPropertiesData()
		if (refetch) refetch()
		dispatch({ type: "setPropertyDialogData", payload: { dataChanged: true } })
	}

	return (
		<>
			<Dialog open={openEdit} fullWidth={true}>
				<DialogCloseButton closeFunction={setOpenEdit} />
				<CustomDialogTitle>Yksikkötiedon muokkaaminen</CustomDialogTitle>
				<DialogContent>
					<EditEstablishmentDialog functionCallOnDataChange={handleMultiDataRefetch} id={establishment.id} setOpenEdit={setOpenEdit} />
				</DialogContent>
				<CustomDialogActions>
					<></>
				</CustomDialogActions>
			</Dialog>

			<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
				{unitsViewType == "professional" && (
					<>
						<TableCell sx={{ width: "30px" }}>
							<IconButton
								// color='primary'
								// sx={{ background: property.costCenterByCostCenterId.idColor }}
								size="medium"
								onClick={() => setOpen(!open)}
							>
								<ArrowForwardIosIcon fontSize="small" sx={{ transform: open ? "rotate(90deg) translateY(3px)" : "none" }} />
							</IconButton>
						</TableCell>
						<TableCell align="center">{establishment.roadUsageUnitByRoadUsageUnitId.roadUsageUnit}</TableCell>
					</>
				)}
				<TableCell align="center">{establishment.roadCooperativeMemberByRoadCooperativeMemberId.name}</TableCell>
				<TableCell align="center" sx={{ maxWidth: "150px" }}>
					{establishment.roadUnits}
				</TableCell>

				<TableCell align="center">
					<MutationIconButton
						// color='primary'
						size="small"
						onClick={() => setOpenEdit(true)}
					>
						<EditIcon sx={{ width: "20px", color: "rgba(0, 0, 0, 0.54)" }} />
					</MutationIconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						{/* <Paper sx={{ margin: 1 }}> */}
						<TableContainer component={Paper}>
							<Table size="small" aria-label="purchases">
								<TableBody>
									<TableRow>
										<TableCell colspan={2}>Osakas: {establishment.roadCooperativeMemberByRoadCooperativeMemberId?.name}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Painoluku (t): {establishment.roadUsageUnitByRoadUsageUnitId.weight}</TableCell>
										<TableCell>Painoluvun korj.ker: {establishment.weightCorrectionMultiplier}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Käyttömatka (km): {establishment.operatingDistance}</TableCell>
										<TableCell>Matkan korj.ker: {establishment.distanceCorrectionMultiplier}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Lukumäärä (ha/kpl): {establishment.amount}</TableCell>
										<TableCell>Harkinnanvarainen korj.ker: {establishment.discretionalyCorrectionMultiplier}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						{/* </Paper> */}
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}

const AddProperty = () =>
	// { propertyData,
	// refetch,
	//  setPropertyDialogData
	//  }: { setPropertyDialogData: any; propertyData: any; refetch?: any }
	{
		const { state, dispatch } = useMapState()

		const [selectedCostCenter, setSelectedCostCenter] = useState<null | number>(-1)
		const [propertyName, setPropertyName] = useState("")

		const { data, loading, error } = useQuery(GET_COST_CENTERS)
		const [createProperty] = useMutation(PROPERTY_MUTATION)

		const handleAddProperty = async () => {
			try {
				const MML_API_KEY = config.MML_API_KEY
				const propertyDataFromMML = await fetch(
					`https://avoin-paikkatieto.maanmittauslaitos.fi/kiinteisto-avoin/simple-features/v3/collections/PalstanSijaintitiedot/items/${state.propertyDialogData?.plotId}?api-key=${MML_API_KEY}`
				)
				const data = await propertyDataFromMML.json()
				const coordinates = data.geometry.coordinates
				console.log(coordinates)
				createProperty({
					variables: {
						costCenterId: selectedCostCenter,
						mmlPropertyId: state.propertyDialogData?.mmlPropertyId,
						plotId: Number(state.propertyDialogData?.plotId),
						propertyName: propertyName,
						coordinates: coordinates,
						type: "create",
						from: "homePage"
					},
					onCompleted(data) {
						dispatch({ type: "setPropertyDialogData", payload: { dataChanged: true } })
						const el: any = document.getElementsByClassName("map-property-info-popup")
						if (el) el[0].style.display = "none"
					},
					onError(error) {
						console.error(error)
					}
				})
			} catch (error) {
				console.error(error)
			}
		}

		return (
			<>
				<Box>
					<Stack>
						<TextField
							label="Tie"
							select
							fullWidth={true}
							sx={{
								maxWidth: "218px"
							}}
							value={selectedCostCenter}
							onChange={(e: { target: { value: number } }) => setSelectedCostCenter(e.target.value)}
						>
							{data && !loading && !error ? (
								data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.map((cc: CostCenter) => {
									return (
										<MenuItem key={cc.id} value={cc.id}>
											{cc.name}
										</MenuItem>
									)
								})
							) : (
								<MenuItem>Ladataan tietoja</MenuItem>
							)}
						</TextField>
						{/* <TextField disabled label="Kiinteistötunnus" value={propertyData.mmlPropertyId}></TextField> */}
						<TextField
							disabled={selectedCostCenter == -1 ? true : false}
							label="Tilan nimi"
							onChange={(e: { target: { value: string } }) => setPropertyName(e.target.value)}
						></TextField>
						<Button variant="contained" onClick={handleAddProperty} sx={{ position: "absolute", bottom: "30px", right: "20px" }}>
							Tallenna
						</Button>
					</Stack>
				</Box>
			</>
		)
	}

export default MapPropertyInfoPopup
