import React, { useEffect, useState } from "react"
import { IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

interface UnitRowPropsType {
	unit: Unit
	index: number
	setAllUnits: React.Dispatch<React.SetStateAction<any[]>>
	costCenterProperties: any
	allMembers: RoadCooperativeMemberType[]
}

interface Unit {
	identifier: string
	partnerId: number
	property: Property | { mmlPropertyId: string; propertyName: string; id: number }
	roadUsageUnitType: string
	roadUsageUnit: string
	weight: number
	amount: number
	operatingDistance: number
	discretionalyCorrectionMultiplier: number
	distanceCorrectionMultiplier: number
	weightCorrectionMultiplier: number
	additionalInformation: string
}

const BulkImportUnitRow = React.memo(
	({ unit, index, setAllUnits, costCenterProperties, allMembers }: UnitRowPropsType) => {
		const [updatedUnitData, setUpdatedUnitData] = useState<Unit>(unit)

		const [roadUnist, setRoadUnits] = useState(0)
		// const [ selectedProperty, setSelectedProperty ] = useState<Property>()

		// console.log(costCenterProperties);
		// console.log(index, " ---------------- INDEX ------------------ ");
		// console.log(allMembers);

		// Deactivate window.alert
		window.alert = function () {
			null
		}

		useEffect(() => {
			setUpdatedUnitData(unit)
		}, [])

		useEffect(() => {
			setAllUnits((prevData: Unit[]) => {
				const index2 = prevData.findIndex((unit2) => unit2.identifier === unit.identifier)
				prevData[index2] = updatedUnitData
				return [...prevData]
			})
		}, [updatedUnitData])

		const handleChangeUnitData = (field: any, value: any) => {
			setUpdatedUnitData({ ...updatedUnitData, [field]: value })
		}

		const handleChangeProperty = (propertyId: number) => {
			if (propertyId == -1) handleChangeUnitData("property", { mmlPropertyId: "", propertyName: "", id: -1 })
			for (let i = 0, len = costCenterProperties.length; i < len; i++) {
				if (costCenterProperties[i].id == propertyId) {
					handleChangeUnitData("property", costCenterProperties[i])
					return
				}
			}
		}

		useEffect(() => {
			const newRoadUnitValue =
				updatedUnitData.discretionalyCorrectionMultiplier *
				updatedUnitData.distanceCorrectionMultiplier *
				updatedUnitData.weightCorrectionMultiplier *
				updatedUnitData.amount *
				updatedUnitData.operatingDistance *
				updatedUnitData.weight
			setRoadUnits(Math.round(newRoadUnitValue))
		}, [
			updatedUnitData.discretionalyCorrectionMultiplier,
			updatedUnitData.distanceCorrectionMultiplier,
			updatedUnitData.weightCorrectionMultiplier,
			updatedUnitData.amount,
			updatedUnitData.operatingDistance,
			updatedUnitData.weight
		])

		const handleChangePartner = (partnerId: number) => {
			handleChangeUnitData("partnerId", partnerId)
		}

		const handleDeleteUnit = () => {
			setAllUnits((prevData: Unit[]) => prevData.filter((unit2, index2) => unit2.identifier != unit.identifier))
		}

		return (
			<>
				<td className="bulk-cell icon-cell" onClick={handleDeleteUnit}>
					<IconButton>
						<DeleteIcon />
					</IconButton>
				</td>
				<td className="bulk-cell">
					<select
						value={updatedUnitData.partnerId}
						className="bulk-input bulk-select"
						name="partnerId"
						id="partnerId"
						onChange={(e: any) => handleChangePartner(e.target.value)}
					>
						<option value={-1}>Valitse osakas</option>
						{allMembers.map((partner: RoadCooperativeMemberType, index: number) => (
							<option key={partner.toString() + index} value={partner.id}>
								{partner.name}
							</option>
						))}
					</select>
				</td>
				<td className="bulk-cell">
					<select
						value={updatedUnitData.property.id}
						className="bulk-input bulk-select"
						name="property"
						id="property"
						onChange={(e: any) => handleChangeProperty(e.target.value)}
					>
						<option value={-1}>Valitse kiinteistö</option>
						{costCenterProperties.map((property: Property, index: number) => (
							<option key={property.toString() + index} value={property.id}>
								{property.mmlPropertyId} {property.propertyName}
							</option>
						))}
					</select>
				</td>
				<td className="bulk-cell">
					<input
						className="bulk-input"
						value={updatedUnitData.roadUsageUnitType}
						onChange={(e: { target: { value: string } }) => handleChangeUnitData("roadUsageUnitType", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						className="bulk-input"
						value={updatedUnitData.roadUsageUnit}
						onChange={(e: { target: { value: string } }) => handleChangeUnitData("roadUsageUnit", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						type="number"
						className="bulk-input"
						value={updatedUnitData.weight}
						onChange={(e: { target: { value: string } }) => handleChangeUnitData("weight", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						type="number"
						className="bulk-input"
						value={updatedUnitData.amount}
						onChange={(e: { target: { value: string } }) => handleChangeUnitData("amount", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						type="number"
						className="bulk-input"
						value={updatedUnitData.operatingDistance}
						onChange={(e: { target: { value: string } }) => handleChangeUnitData("operatingDistance", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						type="number"
						className="bulk-input"
						value={updatedUnitData.distanceCorrectionMultiplier}
						onChange={(e: { target: { value: string } }) => handleChangeUnitData("distanceCorrectionMultiplier", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						type="number"
						className="bulk-input"
						value={updatedUnitData.weightCorrectionMultiplier}
						onChange={(e: { target: { value: string } }) => handleChangeUnitData("weightCorrectionMultiplier", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input
						type="number"
						className="bulk-input"
						value={updatedUnitData.discretionalyCorrectionMultiplier}
						onChange={(e: { target: { value: string } }) => handleChangeUnitData("discretionalyCorrectionMultiplier", e.target.value)}
					/>
				</td>
				<td className="bulk-cell">
					<input className="bulk-input" value={roadUnist} />
				</td>
				<td className="bulk-cell">
					<input
						className="bulk-input"
						value={updatedUnitData.additionalInformation}
						onChange={(e: { target: { value: string } }) => handleChangeUnitData("additionalInformation", e.target.value)}
					/>
				</td>
			</>
		)
	},
	(prev, next) => {
		return true
	}
)

BulkImportUnitRow.displayName = "UnitRow"

export default BulkImportUnitRow
