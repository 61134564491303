import React, { useEffect, useState } from "react"
import { Typography } from "@mui/material"
import { Button } from "@mui/material"
import { CircularProgress } from "@mui/material"
import { Stack } from "@mui/material"
import { Backdrop } from "@mui/material"

import { Stepper, Step, StepLabel } from "@mui/material"
import { Box } from "@mui/material"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Paper } from "@mui/material"
import { TableContainer } from "@mui/material"
import { Table } from "@mui/material"
import { TableHead } from "@mui/material"
import { TableRow } from "@mui/material"
import { TableCell } from "@mui/material"
import { TableBody } from "@mui/material"
import { TextField } from "@mui/material"
import { UnitsImportMap } from "./UnitsImportMap"
import { Chip } from "@mui/material"
import { Dialog } from "@mui/material"
import DialogCloseButton from "../../reusables/DialogCloseButton"
import { DialogContent } from "@mui/material"
import CustomDialogActions from "../../reusables/CustomDialogActions"
import { toast } from "react-toastify"

type Est = {
	id: number
}

const GET_ALL_ESTABLISHMENTS = gql`
	query GetAllEstablishmentsy {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					id
					establishmentsByCostCenterId {
						nodes {
							amount
							id
							propertyByPropertyId {
								mmlPropertyId
								propertyName
							}
							roadCooperativeMemberByRoadCooperativeMemberId {
								name
							}
							costCenterByCostCenterId {
								idColor
								name
							}
						}
					}
				}
			}
			totalUnits
		}
	}
`

const GET_ESTABLISHMENTS_BY_COST_CENTER_ID = gql`
	query GetAllEstablishmentsy($costCenterId: IdType = "") {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					establishmentsByCostCenterId(condition: { costCenterId: $costCenterId }) {
						nodes {
							id
						}
					}
				}
			}
		}
	}
`
const GET_TOTAL_UNITS = gql`
	query GetTotalUnits {
		roadCooperativeWithJWT {
			totalUnits
		}
	}
`

const DELETE_ALL_UNITS = gql`
	mutation deleteEstablishment($id: Int = 10) {
		deleteEstablishmentById(input: { id: $id }) {
			deletedEstablishmentId
		}
	}
`

const UPDATE_UNIT = gql`
	mutation UpdateUnitById($amount: Float = 1.5, $id: Int = 10) {
		updateEstablishmentById(input: { establishmentPatch: { amount: $amount }, id: $id }) {
			clientMutationId
		}
	}
`

type SimpleUnitsImportProps = {
	activeCostCenter: ActiveCostCenterInterface
	setOpenBulkUnitDataImport: React.Dispatch<React.SetStateAction<boolean>>
	setRoadCooperativeStatusValues: React.Dispatch<
		React.SetStateAction<{
			createRoad: boolean
			createMembers: boolean
			createEstablishments: boolean
		}>
	>
}
const CustomCloseButton = ({ closeToast }: any) => (
	<Button
		sx={{
			position: "absolute", // Sijoittaa napin absoluuttisesti
			top: "5px", // Asettaa napin yläreunaan
			right: "5px", // Asettaa napin oikeaan reunaan
			minWidth: "24px", // Pienentää napin leveyttä
			height: "24px", // Pienentää napin korkeutta
			padding: 0, // Poistaa ylimääräiset marginaalit
			fontSize: "14px", // Pienentää fonttikokoa
			fontWeight: 900,
			lineHeight: 1, // Vähentää riviväliä
			backgroundColor: "transparent", // Taustaväri läpinäkyvä
			color: "#b0b0b0", // Tekstin väri
			"&:hover": {
				backgroundColor: "rgba(0, 0, 0, 0.1)" // Muuttaa hover-tilan taustaa
			}
		}}
		onClick={closeToast}
	>
		╳
	</Button>
)

const SimpleUnitsImport = ({ activeCostCenter, setOpenBulkUnitDataImport, setRoadCooperativeStatusValues }: SimpleUnitsImportProps) => {
	const [step, setStep] = useState(0)
	const [allEstablishments, setAllEstablishments] = useState<Establishment[]>([])
	const [totalUnits, setTotalUnits] = useState(0)
	const [openNotiDialog, setOpenNotiDialog] = useState(false)

	const [deleteEstablishment] = useMutation(DELETE_ALL_UNITS)
	const { data: establishmentsByCostCenterId, refetch: refetchEstByCcId } = useQuery(GET_ESTABLISHMENTS_BY_COST_CENTER_ID, {
		variables: { costCenterId: activeCostCenter.id }
	})
	const handleDeleteEstablishments = async () => {
		refetchEstByCcId()

		const establishmentData = establishmentsByCostCenterId.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[0].establishmentsByCostCenterId.nodes
		console.log(establishmentData)
		establishmentData.map((est: Est) => {
			console.log(est)
			deleteEstablishment({
				variables: { id: est.id }
			})
		})
		refetch()
	}
	useEffect(() => {
		toast(
			`Tämän työkalun avulla voit lisätä yksikkötiedot ohjelmaan. Aloita yksiköiden lisäys valitsemalla ensin osakas listasta, klikkaamalla kiinteistön kartalta ja syöttämällä halutun yksikkömäärän osakkaalle!`,
			{
				role: "global",
				type: toast.TYPE.INFO,
				draggable: true,
				autoClose: false,
				closeButton: <CustomCloseButton closeToast={() => toast.dismiss()} />
			}
		)
	}, [])

	const { data, refetch } = useQuery(GET_ALL_ESTABLISHMENTS, {
		onCompleted(data) {
			let tempAllEst: Establishment[] = []
			for (let i = 0, len = data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
				tempAllEst = tempAllEst.concat(data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[i].establishmentsByCostCenterId.nodes)
			}
			setTotalUnits(data.roadCooperativeWithJWT.totalUnits)
			const sortedEst = tempAllEst.sort((a: any, b: any) => {
				const nameA = a.roadCooperativeMemberByRoadCooperativeMemberId.name.toUpperCase()
				const nameB = b.roadCooperativeMemberByRoadCooperativeMemberId.name.toUpperCase()
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
				return 0
			})
			setAllEstablishments(sortedEst)
		}
	})

	const handlePreviousClick = async () => {
		if (step == 0) {
			refetchEstByCcId()
			if (establishmentsByCostCenterId.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[0].establishmentsByCostCenterId.nodes.length > 0) {
				setOpenNotiDialog(true)
			} else {
				window.location.reload()
				setOpenBulkUnitDataImport(false)
			}

			return
		}
		setStep(step - 1)
	}
	const handleOpenNotiDialog = async () => {
		setOpenNotiDialog(false)

		refetchEstByCcId()

		handleDeleteEstablishments()
		window.location.reload()
		setOpenBulkUnitDataImport(false)

		return
	}

	return (
		<>
			<Stepper activeStep={step} alternativeLabel sx={{ height: "60px" }}>
				<Step>
					<StepLabel sx={{ lineHeight: "0px" }}>Muokkaus</StepLabel>
				</Step>
				<Step>
					<StepLabel sx={{ lineHeight: "0px" }}>Esikatselu</StepLabel>
				</Step>
			</Stepper>
			<Box sx={{ height: "calc(100% - 60px)" }}>
				{
					{
						0: <ConnectMemberWithProperty step={step} setStep={setStep} />,
						1: (
							<CreateUnits
								step={step}
								setStep={setStep}
								setOpenBulkUnitDataImport={setOpenBulkUnitDataImport}
								setRoadCooperativeStatusValues={setRoadCooperativeStatusValues}
								setTotalUnits={setTotalUnits}
								totalUnits={totalUnits}
								allEstablishments={allEstablishments}
							/>
						)
					}[step]
				}
			</Box>
			<Dialog open={openNotiDialog}>
				{/* <DialogCloseButton closeFunction={handleCloseCreateCostCenter} /> */}
				<DialogContent>Oletko varma että haluat jatkaa? Jos peruutat yksiköiden lisäyksen, kaikki syöttämäsi yksiköt poistuvat.</DialogContent>
				<CustomDialogActions>
					<Button variant="outlined" color="cancel" onClick={() => setOpenNotiDialog(false)}>
						Sulje
					</Button>
					<Button variant="contained" color="close" onClick={handleOpenNotiDialog}>
						Peruuta
					</Button>
				</CustomDialogActions>
			</Dialog>
			{/* <Button sx={{ position: "absolute", bottom: "50px", left: "50px" }} variant="contained" onClick={handleClick}>
				{step == 0 ? "Sulje" : "Edellinen"}
			</Button> */}
			{console.log(allEstablishments)}
			<Button sx={{ position: "absolute", bottom: "25px", left: "25px" }} variant="contained" color="close" onClick={handlePreviousClick}>
				{step == 0 ? "Peruuta" : "Edellinen"}
			</Button>
		</>
	)
}

type ConnectMemberWithPropertyProps = {
	step: number
	setStep: React.Dispatch<React.SetStateAction<number>>
}

const ConnectMemberWithProperty = ({ setStep, step }: ConnectMemberWithPropertyProps) => {
	const handleNextClick = async () => {
		setStep(step + 1)
	}

	return (
		<>
			<UnitsImportMap />
			<Button sx={{ position: "absolute", bottom: "25px", right: "25px" }} variant="contained" onClick={handleNextClick}>
				Seuraava
			</Button>
		</>
	)
}

type CreateUnitsProps = {
	step: number
	setStep: React.Dispatch<React.SetStateAction<number>>
	setOpenBulkUnitDataImport: React.Dispatch<React.SetStateAction<boolean>>
	setRoadCooperativeStatusValues: React.Dispatch<
		React.SetStateAction<{
			createRoad: boolean
			createMembers: boolean
			createEstablishments: boolean
		}>
	>
	setTotalUnits: React.Dispatch<React.SetStateAction<number>>
	totalUnits: number
	allEstablishments: Establishment[]
}

const CreateUnits = ({
	setStep,
	step,
	setOpenBulkUnitDataImport,
	setRoadCooperativeStatusValues,
	setTotalUnits,
	totalUnits,
	allEstablishments
}: CreateUnitsProps) => {
	const [loading, setLoading] = useState(false)

	const { data: totalUnitsData, refetch: refetchTotalUnits } = useQuery(GET_TOTAL_UNITS)

	useEffect(() => {
		if (!totalUnitsData) return
		if (!totalUnitsData?.roadCooperativeWithJWT?.totalUnits) {
			setTotalUnits(0)
			return
		}
		setTotalUnits(totalUnitsData.roadCooperativeWithJWT.totalUnits)
	}, [totalUnitsData])

	const saveUnits = async () => {
		setLoading(true)
		// Tallennetaan yksiköt
		console.log("Luodaan yksiköt...")
		setLoading(false)
		setRoadCooperativeStatusValues((prevValue) => {
			return { ...prevValue, createEstablishments: false }
		})
		setOpenBulkUnitDataImport(false)
	}

	return (
		<>
			{loading ? (
				<>
					<Backdrop sx={{ zIndex: 1, width: "100vw", height: "100vh" }} open={loading}>
						<Stack direction="row" sx={{ position: "absolute", zIndex: 2, alignItems: "center" }}>
							<CircularProgress />
							<Typography sx={{ color: "white", marginLeft: "10px" }}>Tallennetaan yksiköitä...</Typography>
						</Stack>
					</Backdrop>
				</>
			) : null}
			<TableContainer component={Paper} sx={{ height: "100%" }} PaperProps={{ sx: { padding: "10px" } }}>
				{/* <CustomNotification severity="info">
					{" "}
					joo adsgadfgadfg asdfasdfasdfasdfadsgadfhadfh afdgsadfgsdfgsdfg sdfgsdfgsdfgsdf sdfgsdfgsdfgsdfgsdfgs aergaergaergaerg aergaergaergaerg
					aergaergaergaerg aergaergaaergaer gaerg aer gaergaergaergaerg aerg aerg aerg aer gaerg aer ae r
				</CustomNotification> */}
				<Typography sx={{ fontWeight: "400", fontSize: "0.8rem", position: "absolute", zIndex: "20", paddingLeft: "10px" }}>
					Tiekunnan yksiköt: {totalUnits}
				</Typography>
				<Table stickyHeader={true}>
					<TableHead>
						<TableRow>
							<TableCell>Osakas</TableCell>
							<TableCell>kiinteistö</TableCell>
							<TableCell>Yksiköt</TableCell>
							<TableCell>Yksiköiden prosenttiosuus</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{allEstablishments.map((est: Establishment) => {
							return (
								<React.Fragment key={est.id}>
									<EstablishmentRow
										establishment={est}
										totalUnits={totalUnits}
										setTotalUnits={setTotalUnits}
										refetchTotalUnits={refetchTotalUnits}
									/>
								</React.Fragment>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<Button sx={{ position: "absolute", bottom: "25px", right: "25px" }} variant="contained" onClick={saveUnits}>
				Tallenna
			</Button>
		</>
	)
}

type EstablishmentRowProps = {
	establishment: Establishment
	totalUnits: number
	setTotalUnits: React.Dispatch<React.SetStateAction<number>>
	refetchTotalUnits: () => void
}

const EstablishmentRow = ({ establishment, setTotalUnits, totalUnits, refetchTotalUnits }: EstablishmentRowProps) => {
	const [units, setUnits] = useState(establishment.amount)
	const [precentageFromTotalUnits, setPrecentageFromTotalUnits] = useState(0)

	const [updateEst] = useMutation(UPDATE_UNIT)

	useEffect(() => {
		// Laske prosenttiosuus kokonaisyksiköistä
		if (!totalUnits) {
			setPrecentageFromTotalUnits(0)
			return
		}
		const precentage = (units / totalUnits) * 100
		setPrecentageFromTotalUnits(Math.floor(precentage))
	}, [totalUnits])

	const handleUnitsChange = async (value: number) => {
		setUnits(value)
		await updateEst({ variables: { amount: Number(value), id: establishment.id } })
		refetchTotalUnits()
	}

	return (
		<TableRow>
			<TableCell>{establishment.roadCooperativeMemberByRoadCooperativeMemberId.name}</TableCell>
			<TableCell>
				<Stack direction="column">
					{establishment.propertyByPropertyId.mmlPropertyId}, {establishment.propertyByPropertyId.propertyName}
					<Chip size="small" label={establishment.costCenterByCostCenterId.name}></Chip>
				</Stack>
			</TableCell>
			<TableCell>
				<TextField value={units} onChange={(e: { target: { value: number } }) => handleUnitsChange(e.target.value)} />
			</TableCell>
			<TableCell>{precentageFromTotalUnits ?? 0}%</TableCell>
		</TableRow>
	)
}

export default SimpleUnitsImport
